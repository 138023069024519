import React from "react"

function ShowMessage({ message }) {
  return (
    <div
      className="ori-flex ori-flex-ac ori-flex-ctr"
      style={{ height: "100%", width: "100%" }}
    >
      <h2>{message}</h2>
    </div>
  )
}

export default ShowMessage
