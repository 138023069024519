import React, { useState } from "react"
import { Modal, Button, Input } from "antd"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
} from "react-icons/ai"

const LikeDislike = ({
  showLikeDisLikeButtons,
  LikeDislikeOptions,
  currJobHistory,
  newJobHistoy,
  item
}) => {
  const [dislikeReason, setDislikeReason] = useState("")
  const [currentVariationFeedback, setCurrentVariationFeedback] = useState({})
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)

  const iconStyle = {
    width: "2rem",
    height: "1.5rem",
    display: "flex",
    placeItems: "center",
    padding: "0.2rem",
  }

  const handleUserlike = ({ item }) => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/jobs/jobLikeDislike/${item.jobId}`,
        method: "POST",
        data: {
          isLiked: true,
        },
      },
      (err, res) => {
        if (LikeDislikeOptions) {
          LikeDislikeOptions.liked = res.data.data.data.isLiked
          LikeDislikeOptions.disliked = res.data.data.data.isDisliked
        }
        let updatedJob = res.data.data.data;
        let njh = currJobHistory.map((job) => {
          if (job._id === updatedJob._id) return updatedJob
          else return job;
        });
        newJobHistoy(njh)
      }
    )
  }

  const handleUserDislike = (openModal = false) => {
    const { item } = currentVariationFeedback

    if (openModal) {
      setIsFeedbackModalOpen(true)
      return;
    }

    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/jobs/jobLikeDislike/${item.jobId}`,
        method: "POST",
        data: {
          isDisliked: true,
          dislikeReason,
        },
      },
      (err, res) => {
        if (LikeDislikeOptions) {
          LikeDislikeOptions.liked = res.data.data.data.isLiked
          LikeDislikeOptions.disliked = res.data.data.data.isDisliked
        }
        let updatedJob = res.data.data.data;
        let njh = currJobHistory.map((job) => {
          if (job._id === updatedJob._id) return updatedJob
          else return job
        })
        newJobHistoy(njh)
      }
    );

    setDislikeReason("")

    setIsFeedbackModalOpen(false)
  }

  return (
    <>
      {/* Modal for Getting dislike reason from user */}

      <Modal
        title="Would you like to tell us what went wrong ?"
        onCancel={() => {
          setIsFeedbackModalOpen(false)
          handleUserDislike()
        }}
        open={isFeedbackModalOpen}
        footer={[
          <Button
            onClick={() => {
              setIsFeedbackModalOpen(false)
              handleUserDislike()
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setIsFeedbackModalOpen(false)
              handleUserDislike()
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Input
          onInput={(e) => setDislikeReason(e.target.value)}
          placeholder="Describe here what went wrong..."
          style={{ width: "100%" }}
          value={dislikeReason}
        />
      </Modal>

      {showLikeDisLikeButtons ? (
        <>
          <Button
            onClick={() => {
              handleUserlike({ item })
            }}
            disabled={
              item.isLiked || (LikeDislikeOptions && LikeDislikeOptions.liked)
            }
            style={{ padding: 0, background: "none" }}
          >
            {/* <LikeOutlined title="Like this content" style={{ padding: "0.5rem", color: variation_data.isLiked ? "green" : "black" }} /> */}

            {item.isLiked ||(LikeDislikeOptions && LikeDislikeOptions.liked) ? (
              <AiFillLike
                title="Like this content"
                size={18}
                style={iconStyle}
                color="green"
              />
            ) : (
              <AiOutlineLike
                title="Like this content"
                color="black"
                size={35}
                style={iconStyle}
              />
            )}
          </Button>

          <Button
            onClick={() => {
              setCurrentVariationFeedback({
                item,
              })

              handleUserDislike(true)
            }}
            disabled={
              item.isDisliked ||
              (LikeDislikeOptions && LikeDislikeOptions.disliked)
            }
            style={{ padding: 0, background: "none" }}
          >
            {/* <DislikeOutlined title="Dislike this content" style={{ padding: "0.5rem", color: variation_data.isDisliked ? "red" : "black" }} /> */}

            {item.isDisliked ||
            (LikeDislikeOptions && LikeDislikeOptions.disliked) ? (
              <AiFillDislike
                title="Like this content"
                color="red"
                size={18}
                style={iconStyle}
              />
            ) : (
              <AiOutlineDislike
                title="Like this content"
                color="black"
                size={18}
                style={iconStyle}
              />
            )}
          </Button>
        </>
      ) : null}
    </>
  );
};

export default LikeDislike;