import React, {
  Component,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorContext } from "../../AppContainer"
import draftToHtml from "draftjs-to-html"
import jsPDF from 'jspdf'
import { convertToRaw, EditorState, ContentState } from "draft-js"
import { Button, DatePicker, message, Modal, Select, Dropdown, Menu } from "antd"
import { PlusOutlined, SaveOutlined , CloudDownloadOutlined } from "@ant-design/icons"
import { fetchDataAndProceed, wordCount } from "../../data/utils/datautility"
import { connect } from "react-redux"
import { json, useLocation, unstable_useBlocker } from "react-router-dom"

import { postTweet } from "../../data/redux/social_permissions/action"
import { updatePageState } from "../../data/redux/page_details/action"
import TweetComponent from "../TweetComponent"
import LikeDislike from "../History/LikeDislike"
import moment from "moment"
import usePrompt from "../../data/customHooks/usePrompt"
// import htmlToDraft from 'html-to-draftjs';

function EditorComponent({ socialaccount, dispatch, generated }) {
  const state = useContext(EditorContext)
  const [selectedAccount, setSelectedAccount] = useState()
  const [modalState, setModalState] = useState({})
  const [scheduleDate, setScheduleDate] = useState("")
  const [showAccount, setShowAccount] = useState(false)
  const location = useLocation()
  const [selectionText, setSelectionText] = useState({
    characterCount: "",
    Count: "",
    tooltipVisible: false,
    tooltipPosition: { top: 0, left: 0 },
  })
  const editorRef = useRef(null)
  let plainTextLength = state.editorState.getCurrentContent().getPlainText("\u0001")?.length

  useEffect(() => {
    if(plainTextLength > 0 && Object.keys(generated.output).length > 0)
    {
      dispatch(updatePageState({
        generated: {
          ...generated, 
          content: true,
          route: window.location.pathname,
        }
      }))
    }
    else 
    {
      dispatch(updatePageState({
        generated: {
          ...generated,
          content: false,
          route: window.location.pathname,
        }
      }))
    }
  }, [dispatch, state.editorState])
  
  useEffect(() => {
    // const pathend = location.pathname.split("/");
    // console.log(pathend, "hello");
    // if (pathend[pathend.length - 1] == "socialplatform") setShowAccount(true);

    return () => {
      dispatch(updatePageState({
        generated: {
          ...generated,
          LikeDislikeOptions: {}
        }
      }))
      state.setEditorState(EditorState.createEmpty())
    }
  }, [])

  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)
      const boundingRect = range.getBoundingClientRect()

      const selectedText = selection.toString()
      const selectedTextCharCount = selectedText.length
      const selectedTextWordCount = selectedText.trim().split(/\s+/).length

      setSelectionText((prevState) => {
        return {
          ...prevState,
          characterCount: selectedTextCharCount,
          Count: selectedTextWordCount,
        }
      })
      const tooltipTop = boundingRect.top + window.pageYOffset - 60
      const tooltipLeft =
        boundingRect.left + window.pageXOffset - 40 + boundingRect.width / 2
      setSelectionText((prevState) => {
        return {
          ...prevState,
          tooltipVisible: selectedTextCharCount > 0,
          tooltipPosition: { top: tooltipTop, left: tooltipLeft },
        }
      })
    }

    document.addEventListener("click", handleSelection)

    return () => {
      document.removeEventListener("selectionchange", handleSelection)
    }
  }, [])
  //   console.log(
  //     draftToHtml(convertToRaw(state?.editorState?.getCurrentContent()))
  //   );

  // console.log(socialaccount);
  const checkWhen = () => {
    return state.editorState.getCurrentContent().getPlainText("\u0001")?.length > 0;
  }

  usePrompt(
    "Are you sure you want to leave?\nYou will lose all the unsaved data.",
    checkWhen()
  )
  const postOnsocial = (type) => {
    const payload = {
      identifier: selectedAccount.identifier,
      tweetPost: state.editorState.getCurrentContent().getPlainText("\u0001"),
      postSchedule: type == 0 ? "now" : scheduleDate,
    }
    dispatch(postTweet(payload))
  }

  const renderModal = () => {
    const payload = {
      user: selectedAccount,
      tweetPost: state.editorState.getCurrentContent().getPlainText("\u0001"),
    }
    return (
      <>
        <h3 style={{ textTransform: "capitalize" }}>{modalState?.type}</h3>

        <span style={{ fontWeight: "500" }}>Tweet Preview</span>

        <TweetComponent data={payload} />
        <div className="ori-flex" style={{ justifyContent: "flex-end" }}>
          {modalState?.postnow ? (
            <Button
              onClick={() => postOnsocial(0)}
              style={{ marginRight: "10px" }}
              type="primary"
            >
              Post Now
            </Button>
          ) : (
            <>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                onChange={(value, date) => {
                  const dateString =
                    date.substring(0, 10) + "T" + date.substring(11) + ":00Z"
                  // console.log(dateString);
                  setScheduleDate(dateString)
                }}
                showTime={{
                  format: "HH:mm",
                }}
                disabledDate={(current) => {
                  return moment().add(-1, "days") >= current
                }}
                style={{ marginRight: "10px" }}
              />
              <Button type="primary" onClick={() => postOnsocial(1)}>
                Schedule post
              </Button>
            </>
          )}
        </div>
      </>
    )
  }

  const openModal = (postnow = true) => {
    if (!selectedAccount) return message.error("Please choose a social account")

    if (state.editorState.getCurrentContent().getPlainText("\u0001") == 0)
      return message.error("Please write something to post")

    setModalState({
      type: selectedAccount?.smType,
      postnow: postnow,
    })
  }

  const newPost = () => {
    state.setEditorState(EditorState.createEmpty())
  }

  const savePost = () => {
    if (
      state.editorState.getCurrentContent().getPlainText("\u0001").length === 0
    ) {
      return message.error("Empty post can not be saved")
    }
    const htmlstring = draftToHtml(
      convertToRaw(state?.editorState?.getCurrentContent())
    ).toString()

    const payload = {
      post: htmlstring,
    }
    console.log(payload)
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/post`,
        method: "POST",
        data: payload,
      },
      (err, res) => {
        if (!err && res) {
          message.success("Post Saved Successfully")
          state.setEditorState(EditorState.createEmpty())
        }
      }
    )
  }

  const handleDownload = (format) => {
    const content = state.editorState.getCurrentContent().getPlainText()
    if(format === "pdf"){
      const pdf = new jsPDF('p', 'pt','a4', true)
      pdf.setFontSize(9)  
      const lineHeight = 20
      let y = 30
      const lines = pdf.splitTextToSize(content, pdf.internal.pageSize.getWidth() - 40)
      lines.forEach((line) => {
        if (y + lineHeight > pdf.internal.pageSize.getHeight() - 20) {
          pdf.addPage()
          y = 30
        }
        pdf.text(line, 20, y)
        y += lineHeight
      })
      pdf.save('Content.pdf')
    }
    else {
      const element = document.createElement('a')
      const file = new Blob([content], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = `Content.${format}` 
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }

  const dropdownMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div onClick={()=>handleDownload("pdf")}>
             PDF Format
            </div>
          )
        },
        {
          key: "2",
          label: (
            <div onClick={()=>handleDownload("docx")}>
              Doc Format
            </div>
          )
        }
      ]}
    />
  )

  return (
    <>
      <div
        className="ori-flex ori-flex-jsb ori-flex-ac"
        style={{ marginBottom: "20px" }}
      >
        <div style={{ color: "#6D6D6D" }}>Output</div>
        {showAccount && (
          <div>
            <Select
              style={{ width: "150px" }}
              value={selectedAccount?.username}
              placeholder="Select an account"
              onChange={(val) => {
                const obj = JSON.parse(val)
                setSelectedAccount(obj)
              }}
            >
              {/* <Select.OptGroup label="Facebook">
              {socialaccount
                ?.filter((item) => item.smType == 'facebook')
                ?.map((item) => {
                  return (
                    <option>
                      <span>{item?.username}</span>
                    </option>
                  );
                })}
            </Select.OptGroup> */}
              <Select.OptGroup label="Twitter">
                {" "}
                {socialaccount
                  ?.filter((item) => item.smType == "twitter")
                  ?.map((item) => {
                    return (
                      <option value={JSON.stringify(item)}>
                        {item?.username}
                      </option>
                    )
                  })}
              </Select.OptGroup>
              {/* <Select.OptGroup label="Instagram"></Select.OptGroup>
            <Select.OptGroup label="Linkedin"></Select.OptGroup> */}
            </Select>
          </div>
        )}

        <div>
          {/* <Button
            icon={<PlusOutlined />}
            style={{ width: "124px", height: "40px", marginRight: "20px" }}
            onClick={newPost}
          >
            {" "}
            New Post
          </Button> */}
        </div>
      </div>{" "}
      <Editor
        editorState={state.editorState}
        onEditorStateChange={(editstate) => {
          state.setEditorState(editstate)
          setSelectionText((prevState) => {
            return {
              ...prevState,
              tooltipVisible: false,
            }
          })
        }}
        editorStyle={{
          background: "#f9f9f9",
          height: "calc(100vh - 300px)",
          padding: "0px 20px",
          marginTop: "20px",
        }}
        toolbarStyle={{
          background: "#f9f9f9",
        }}
        editorRef={(ref) => {
          editorRef.current = ref
        }}
      />
      {selectionText.tooltipVisible && (
        <div
          style={{
            position: "absolute",
            zIndex: "999",
            backgroundColor: "#ffffff",
            border: "1px solid #aaa",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "3px",
            padding: "5px",
            top: selectionText.tooltipPosition.top,
            left: selectionText.tooltipPosition.left,
          }}
        >
          <div>
            <strong>{selectionText.Count}</strong> Words
          </div>
          <div>
            <strong>{selectionText.characterCount}</strong> Characters
          </div>
        </div>
      )}
      <div
        style={{ marginTop: "20px", flexWrap: "wrap" }}
        className="ori-flex ori-flex-ac ori-flex-jsb"
      >
        <div className="ori-flex" style={{ color: "#6D6D6D"  }} >
          <div>
            Words{" "}
            {wordCount(
              state.editorState.getCurrentContent().getPlainText("\u0001")
            )}
          </div>
          <div style={{ marginLeft: "10px" }}>
            Characters{" "}
            {
              state.editorState.getCurrentContent().getPlainText("\u0001")
                ?.length
            }
          </div>
        </div>
        <div className="ori-flex" style={{ marginTop : "4px" }} >
        {plainTextLength > 0 && Object.keys(generated.output).length > 0 && (
          <>
            {/* <Dropdown overlay={dropdownMenu} placement="bottom">
              <Button  
                icon={<CloudDownloadOutlined/>}
                style={{ marginRight: "20px" }}
              >
                Download
              </Button>
            </Dropdown> */}
            <div className="ori-flex" style={{ marginRight: "20px" }}>
              <LikeDislike
                item={generated.output}
                showLikeDisLikeButtons={true}
                LikeDislikeOptions={generated.LikeDislikeOptions}
              />
            </div>
          </>
        )}
          <Button
            icon={<SaveOutlined />}
            style={{ width: "112px", height: "32px"}}
            onClick={savePost}
          >
            Save Post
          </Button>
        </div>
        {/* <Button
            style={{ marginRight: '20px', height: '40px' }}
            type="primary"
            onClick={() => openModal(false)}
          >
            Post Later
          </Button> */}
      </div>
      <Modal
        open={modalState?.type}
        footer={false}
        onCancel={() => setModalState({})}
      >
        {renderModal()}
      </Modal>
    </>
  )
}

function mapstatetoprops(state) {
  return {
    socialaccount: state.auth.user.socialAccounts,
    generated: state.page_details.generated
  }
}

export default connect(mapstatetoprops)(EditorComponent)
