import { PlusCircleOutlined, RightCircleOutlined } from "@ant-design/icons"
import { Button, Drawer, Select } from "antd"
import React, { useState } from "react"
import { connect } from "react-redux"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import CardContainer from "../CardContainer"
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  SaveFilled,
  SaveOutlined,
  PlusOutlined,
} from "@ant-design/icons"

function SocialAccounts({ socialaccount }) {
  console.log(socialaccount, "socialllls")
  const [drawerState, setDrawerState] = useState(false)

  const icons = {
    twitter: <TwitterOutlined />,
    linkiedin: <LinkedinOutlined />,
  }

  const options = [
    {
      value: "all",
      label: "All Accounts",
    },
    {
      value: "twitter",
      label: "Twitter",
    },
    // {
    //   value: 'facebook',
    //   label: 'Facebook'
    // },

    // {
    //   value: 'instagram',
    //   label: 'Instagram'
    // },
    // {
    //   value: 'linkedin',
    //   label: 'Linkedin'
    // }
  ]

  const startTwitterVerify = () => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/social/twitter-auth-url`,
        method: "GET",
      },
      (err, res) => {
        console.log(res)
        if (!err && res) {
          window.open(res?.data?.data, "_blank")
        }
      }
    )
  }

  const renderAddsocialMedia = () => {
    return (
      <div>
        {/* <div
          style={{
            padding: '10px 0px',
            borderBottom: '1px solid gray',
            cursor: 'pointer'
          }}
          className="ori-flex ori-flex-jsb"
        >
          <span>Facebook</span>

          <RightCircleOutlined />
        </div> */}
        <div
          style={{
            padding: "10px 0px",
            borderBottom: "1px solid gray",
            cursor: "pointer",
          }}
          className="ori-flex ori-flex-jsb"
          onClick={startTwitterVerify}
        >
          <span>Twitter</span>
          <RightCircleOutlined />
        </div>
        {/* <div
          style={{
            padding: '10px 0px',
            borderBottom: '1px solid gray',
            cursor: 'pointer'
          }}
          className="ori-flex ori-flex-jsb"
        >
          <span>Instagram</span>
          <RightCircleOutlined />
        </div>
        <div
          style={{
            padding: '10px 0px',
            borderBottom: '1px solid gray',
            cursor: 'pointer'
          }}
          className="ori-flex ori-flex-jsb"
        >
          <span>Linkedin</span>
          <RightCircleOutlined />
        </div> */}
      </div>
    )
  }

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <h1>Social Accounts</h1>
        <p>
          Add your social media accounts to schedule your posts from our app
          itself.
        </p>
      </div>

      <div style={{ width: "90%", margin: "auto" }}>
        <div style={{ width: "100%" }}>
          <div className="ori-flex ori-flex-ac ori-flex-jsb">
            <span style={{ fontSize: "30px", fontWeight: "400" }}>
              Accounts
            </span>
            <div>
              <Select
                options={options}
                defaultValue={"all"}
                style={{ width: "120px", marginRight: "20px" }}
              ></Select>
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => setDrawerState(true)}
              >
                Add Account
              </Button>
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div
              style={{ filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.07))" }}
            >
              <div
                className="ori-flex ori-flex-jsb ori-flex-ac"
                style={{
                  padding: "10px 0px",
                  background: "#5816AE",
                  height: "58px",
                  color: "white",
                }}
              >
                <div style={{ marginLeft: "25px", fontSize: "14px" }}>Name</div>
                <div
                  style={{ width: "250px" }}
                  className="ori-flex ori-flex-ac ori-flex-jsb"
                >
                  <span style={{ fontSize: "14px" }}>Username</span>
                  <span style={{ fontSize: "14px", marginRight: "65px" }}>
                    Type
                  </span>
                </div>
              </div>
              <div
                style={{
                  height: "600px",
                  overflowY: "auto",
                }}
              >
                {socialaccount &&
                  socialaccount.map((item, index) => {
                    return (
                      <div
                        style={{
                          height: "70px",
                          background:
                            index % 2 ? "rgba(247, 247, 247, 0.5)" : "",
                          cursor: "pointer",
                        }}
                        className="ori-flex ori-flex-jsb ori-flex-ac ori-bg-white"
                      >
                        <div
                          style={{
                            marginLeft: "25px",
                            fontSize: "14px",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          style={{ width: "250px" }}
                          className="ori-flex ori-flex-ac ori-flex-jsb"
                        >
                          <span style={{ fontSize: "14px" }}>
                            {item.username}
                          </span>
                          <div style={{ marginRight: "30px" }}>
                            <span>{icons[item?.smType]}</span>
                            <span
                              style={{
                                fontSize: "14px",
                                marginLeft: "10px",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.smType}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                {socialaccount?.length == 0 && (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      You have not add an social account{" "}
                    </div>
                    <Button
                      icon={<PlusCircleOutlined />}
                      type="primary"
                      onClick={() => setDrawerState(true)}
                    >
                      Add Account
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={drawerState}
        onClose={() => setDrawerState(false)}
        title="Add Account"
      >
        {renderAddsocialMedia()}
      </Drawer>
    </div>
  )
}

function mapstatetoprops(state) {
  return {
    socialaccount: state.auth.user.socialAccounts,
  }
}

export default connect(mapstatetoprops)(SocialAccounts)
