import React, { useEffect } from "react"
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { fetchDataAndProceed } from "../../data/utils/datautility"

function SocialLogin() {
  const [params] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (params.get("state") && params.get("code")) {
      fetchDataAndProceed(
        {
          url: `${
            process.env.REACT_APP_BASE_URL
          }/social/twitterCallback?state=${params.get(
            "state"
          )}&code=${params.get("code")}`,
          method: "GET",
        },
        (err, res) => {
          if (!err && res) {
            console.log(res)
            navigate("/tool/socialaccounts")
          }
        }
      )
    }
  }, [])
  console.log(params.get("state"), "harhshshs")
  return <div>SocialLogin</div>
}

export default SocialLogin
