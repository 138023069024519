import { UPDATE_SELECTED_TILE_DATA } from "./actiontype"

const initialState = {
  selectedTile: {},
}

const routestate = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_TILE_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default routestate
