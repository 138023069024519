import React from "react"
import ReactDOM from "react-dom/client"
import "./data/styles/index.scss"
import { BrowserRouter as Router } from "react-router-dom"

import App from "./App"
import "./App.less"
import reportWebVitals from "./reportWebVitals"
import { Auth0Provider } from "@auth0/auth0-react"
import { loadCleverTapScript } from "./data/utils/datautility"
import store from "./data/redux/store"
import { Provider } from "react-redux"

const root = ReactDOM.createRoot(document.getElementById("root"))

if (process.env.NODE_ENV === "production") console.log = () => {}

const Store = store
console.log(process.env.REACT_APP_OAUTH_DOMAIN)
root.render(
  <Provider store={Store}>
    <Auth0Provider
      domain={process.env.REACT_APP_OAUTH_DOMAIN}
      clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
      scope={process.env.REACT_APP_OAUTH_SCOPE}
      audience={process.env.REACT_APP_OAUTH_AUDIENCE}
      redirectUri={window.location.origin + "/tool"}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      skipRedirectCallback={window.location.pathname !== "/tool"}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  </Provider>
)

if (
  process.env.REACT_APP_CLEVERTAP_ENABLE ||
  process.env.REACT_APP_CLEVERTAP_TOKEN
) {
  let src =
    ("https:" === document.location.protocol
      ? "https://d2r1yp2w7bby2u.cloudfront.net"
      : "http://static.clevertap.com") + "/js/a.js"
  loadCleverTapScript(src, process.env.REACT_APP_CLEVERTAP_TOKEN)
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
