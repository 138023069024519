import { Button, Input, message } from "antd"
import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { fetchDataAndProceed } from "../../data/utils/datautility"

function RedeemCode() {
  const [code, setCode] = useState("")
  const location = useLocation()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const redeemFeatures = [
    "35000 words per month / Lifetime",
    "Audio/Video Transcription 1500 seconds per month / Lifetime",
    "Social Media Scheduler",
    "Email Generator",
    "Social Media Generator",
    "Blog Generator",
    "Seo Optimisation",
    "Mobile/Website Text Generator",
    "Notes and Text Editor",
    "20+ pre-built template",
    "Active Customer Support",
    "All New Features",
  ]

  const redeem = () => {
    fetchDataAndProceed(
      {
        url: process.env.REACT_APP_BASE_URL + "/operations/coupon",
        method: "POST",
        data: {
          code,
        },
      },
      (err, res) => {
        console.log(err, res)
        setCode("")
        if (!err && res) {
          message.success(
            "Code Applied! Please refresh your page and then check quota"
          )
        } else {
          message.error(err?.response?.data?.message)
        }
      }
    )
  }

  return (
    <div style={{ padding: "20px" }}>
      <div
        className="ori-flex ori-flex-ctr ori-flex-ac"
        style={{ flexDirection: "column" }}
      >
        <span style={{ fontSize: "30px", fontWeight: "600" }}>Redeem Code</span>
        <span style={{ fontSize: "16px", fontWeight: "500", color: "#6D6D6D" }}>
          Please enter your code below to apply your discount.
        </span>

        <div style={{ width: !isMobile ? "450px" : "100%", marginTop: "75px" }}>
          <label style={{ fontSize: "17px" }}>Code</label>
          <br></br>
          <Input
            style={{ width: "100%", height: "50px" }}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter Code"
          ></Input>
          <br></br>

          <Button
            onClick={redeem}
            type="primary"
            style={{ marginTop: "20px", width: "100%", height: "50px" }}
          >
            Redeem
          </Button>

          <ul style={{ marginLeft: "-25px" }}>
            {redeemFeatures.map((item) => {
              return (
                <li style={{ fontSize: "16px", margin: "5px 0px" }}>{item}</li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default RedeemCode
