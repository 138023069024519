import { Avatar } from "antd"
import React from "react"
import "./tweet.css"
import logo from "../../data/assets/twitterlogo.png"
import { FcLike } from "react-icons/fc"
import { FaRetweet } from "react-icons/fa"
import { FaComment } from "react-icons/fa"

function TweetComponent({ data }) {
  const date = new Date()

  return (
    <div className="tweet-container">
      <div className="tweet-header ori-flex ori-flex-ac ">
        <Avatar style={{ width: "48px", height: "48px" }} />
        <span style={{ marginLeft: "5px" }}>
          <span style={{ fontWeight: "600", fontSize: "14px" }}>
            {data?.user?.name}
          </span>
          <br />
          <span style={{ fontSize: "13px", color: "#536471" }}>
            {data?.user?.username}
          </span>
        </span>

        <img src={logo} style={{ width: "25px", marginLeft: "auto" }}></img>
      </div>
      <div className="tweet-content">
        <div>{data?.tweetPost}</div>

        <div style={{ fontSize: "14px", color: "#536471", marginTop: "6px" }}>
          {date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
          ,
          <span style={{ marginLeft: "10px" }}>
            {date.toDateString().substring(4)}
          </span>
        </div>
      </div>
      <div className="tweet-footer ori-flex ">
        <FcLike fontSize={18} style={{ marginLeft: "5px" }} />
        <FaComment
          color="rgb(29, 155, 240)"
          fontSize={18}
          style={{ marginLeft: "15%" }}
        />
        <FaRetweet fontSize={18} style={{ marginLeft: "15%" }} />
      </div>
    </div>
  )
}

export default TweetComponent
