import { useAuth0 } from "@auth0/auth0-react"
import { Button, Dropdown, Menu, Space, Tag } from "antd"
import React from "react"
import "./navbar.css"
import logo from "../../Logo.png"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { DownOutlined, DropboxOutlined } from "@ant-design/icons"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

const TagGenerator = ({ plan_type }) => {
  let color = ""
  if (plan_type == "Gold") color = "gold"
  if (plan_type == "Silver") color = "silver"
  if (plan_type == "Bronze") color = "#CD7F32"

  return (
    <Tag
      color={color}
      style={{
        width: "80px",
        height: "30px",
        fontSize: "14px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "10px",
      }}
    >
      {plan_type}
    </Tag>
  )
}

function NavBar({ showIcon, user }) {
  const { logout } = useAuth0()
  const navigate = useNavigate()

  const getPercentage = (num, den) => {
    // console.log(Math.round((num / den) * 100), "sads", num);

    return Math.round((num / den) * 100)
  }

  const quotamenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div style={{ width: 230 }}>
              <div style={{ color: "#7000f4" }}>Words quota</div>
              <div className="ori-flex" style={{ marginTop: "10px" }}>
                <div style={{ width: 50, height: 50 }}>
                  <CircularProgressbar
                    value={getPercentage(user?.quota_used, user?.quota)}
                    text={`${getPercentage(user?.quota_used, user?.quota)}%`}
                    styles={buildStyles({
                      pathColor: "#7000f4",
                      textColor: "#7000f4",
                    })}
                  />
                </div>
                <div style={{ marginLeft: "10px", fontSize: "12px" }}>
                  <span> Words used - {user?.quota_used}</span>
                  <br></br>
                  <span> Words alloted - {user?.quota}</span>
                </div>
              </div>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div style={{ width: 230 }}>
              <div style={{ color: "#7000f4" }}>Transcription quota</div>
              <div className="ori-flex" style={{ marginTop: "10px" }}>
                <div style={{ width: 50, height: 50 }}>
                  <CircularProgressbar
                    value={getPercentage(
                      user?.transcription_used,
                      user?.transcription_quota
                    )}
                    text={`${getPercentage(
                      user?.transcription_used,
                      user?.transcription_quota
                    )}%`}
                    styles={buildStyles({
                      pathColor: "#7000f4",
                      textColor: "#7000f4",
                    })}
                  />
                </div>
                <div style={{ marginLeft: "10px", fontSize: "12px" }}>
                  <span> Seconds used - {user?.transcription_used}</span>
                  <br></br>
                  <span> Seconds alloted - {user?.transcription_quota}</span>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    />
  )

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Button
              style={{ marginRight: "20px" }}
              onClick={() => navigate("/profile")}
              type="text"
            >
              My profile
            </Button>
          ),
        },
        {
          key: "2",
          label: (
            <Button
              style={{ marginRight: "20px" }}
              onClick={() => {
                localStorage.clear()
                logout({ returnTo: "https://autorytr.com" })
              }}
              type="text"
            >
              Logout
            </Button>
          ),
        },
      ]}
    />
  )

  return (
    <div className="navbar ori-padding-20">
      <div
        className="ori-bg-white ori-box-border-radius ori-flex ori-flex-ac ori-flex-jsb"
        style={{ height: "100%", boxShadow: "0 0 30px #e6e6e6" }}
      >
        {showIcon && (
          <div
            className="ori-flex ori-flex-ctr ori-flex-ac"
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginLeft: "20px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/tool")}
          >
            <img src={logo} style={{ marginLeft: "20px", width: "40px" }}></img>
            <span className="ori-mrgn-l-10">Autorytr</span>
          </div>
        )}

        {!showIcon && (
          <div
            className="ori-flex ori-flex-ctr ori-flex-ac"
            style={{
              fontSize: "18px",
              fontWeight: "normal",
              color: "#7000f4",
              marginLeft: "20px",
            }}
          >
            <span>Current plan </span>
            {<TagGenerator plan_type={user?.plan_type} />}
          </div>
        )}

        <div style={{ marginRight: "20px" }}>
          <Dropdown overlay={quotamenu}>
            <span style={{ marginRight: "20px", color: "#7000f4" }}>
              Quota Status
            </span>
          </Dropdown>

          <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <span>{user?.email?.substring(0, 10)}...</span>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

function mapstateToProps(state) {
  return {
    user: state.auth.user,
  }
}

export default connect(mapstateToProps)(NavBar)
