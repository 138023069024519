import { Button, Steps } from "antd"
import React, { useEffect, useState } from "react"
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { fetchUrlParams } from "../../data/utils/datautility"

import Topbar from "../Topbar"
import Article from "./Article"
import ArticleForm from "./ArticleForm"
import Headline from "./Headline"
import Outline from "./Outline"

const items = [
  {
    title: "GET HEADLINES",
  },
  {
    title: "GET OUTLINES",
  },
  {
    title: "GET AN ARTICLE",
  },
]

function ArticleGenerator() {
  const location = useLocation()
  const [individual, setIndvidual] = useState(false)

  const params = useParams()
  const [selectedHeadline, setSelectedHeadline] = useState("")
  const [selectedOutline, setSelectedOutline] = useState([])
  const navigate = useNavigate()
  const [headlineList, setHeadlineList] = useState([])
  const [outlineList, setOutlineList] = useState([])
  const [article, setArticle] = useState("")
  const [queryParams, setQueryParmas] = useState("")
  const [jobId, setJobId] = useState("")

  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)
  const [lg, setlg] = useState(window.innerWidth > 1200)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
      setlg(window.innerWidth >= 1200)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    // const ele = document.getElementById("chatbotContainer");
    // //  console.log(ele, "harshh");
    // ele.style.display = "none";
    // return () => {
    //   ele.style.display = "block";
    // };
  }, [])

  console.log(!selectedHeadline)

  // useEffect(() => {
  //   console.log(location.search);
  //   setQueryParmas(fetchUrlParams(location.search?.substring(1)));
  // }, [location.search]);

  // console.log(queryParams, "hello");

  return (
    <div className="ori-flex ori-flex-wrap">
      <div
        style={{
          width: lg ? "45%" : "100%",
        }}
      >
        <Topbar text={"Article Generator"} />

        <ArticleForm
          headlineList={headlineList}
          article={article}
          setHeadlineList={setHeadlineList}
          selectedHeadline={selectedHeadline}
          outlineList={outlineList}
          setOutlineList={setOutlineList}
          selectedOutline={selectedOutline}
          setArticle={setArticle}
          jobId={jobId}
          individual={individual}
          setJobId={setJobId}
        />
      </div>
      <div
        style={{ width: lg ? "55%" : "100%", height: !isMobile ? "100vh" : "" }}
        className="ori-bg-white ori-padding-20"
      >
        <Steps current={parseInt(params.step)}>
          {items.map((step) => {
            return <Steps.Step {...step} />
          })}
        </Steps>
        <div
          style={{
            height: !isMobile ? "calc(100vh - 120px)" : "",
            overflowY: !isMobile ? "auto" : "hidden",
          }}
        >
          {params.step == "0" && (
            <Headline
              selectedHeadline={selectedHeadline}
              setSelectedHeadline={setSelectedHeadline}
              headlineList={headlineList}
            />
          )}
          {params.step == "1" && (
            <Outline
              outlineList={outlineList}
              selectedOutline={selectedOutline}
              setSelectedOutline={setSelectedOutline}
              individual={individual}
              setIndvidual={setIndvidual}
            />
          )}
          {params.step == "2" && <Article article={article} />}
        </div>

        <div className="ori-flex ori-flex-jsb">
          <Button
            size="large"
            style={{ width: "120px" }}
            disabled={params?.step == "0"}
            onClick={() =>
              navigate(
                `/tool/blog/longForm/${Math.max(
                  0,
                  parseInt(params.step) - 1
                )}?jobId=${jobId}`
              )
            }
          >
            Previous
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ width: "120px" }}
            disabled={(function () {
              if (params.step == "0") {
                return selectedHeadline ? false : true
              } else if (params.step == "1") {
                return selectedOutline?.length ? false : true
              } else return true
            })()}
            onClick={() =>
              navigate(
                `/tool/blog/longForm/${
                  parseInt(params.step) + 1
                }?jobId=${jobId}`
              )
            }
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ArticleGenerator
