import { useState } from "react"
import { getDataFromLocalStorage } from "../../data/utils/datautility"

const TopupPage = ({ user }) => {
  const [country, _setCountry] = useState(getDataFromLocalStorage("country"))

  console.log("Country : ", country)

  return (
    <>
      {country === "IN" ? (
        <>
          <stripe-pricing-table
            pricing-table-id="prctbl_1MzEnJSI0uPxDyzfWZ3LbM6t"
            publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
            client-reference-id={user?.userID}
            customer-email={user?.email}
          ></stripe-pricing-table>

          {/* For Testing */}
          {/* <stripe-pricing-table pricing-table-id="prctbl_1N1qF9SI0uPxDyzfXc1V0yO1"
                            publishable-key="pk_test_51LptSYSI0uPxDyzfPUojErRxeTrSngZ95JXzZlIYN3Wq9dCEO4JqnkqCUD8OPXnn6nb4xZLN6v9GN2dqG1LouPrT00jF5aLo71"
                            client-reference-id={user?.userID}
                            customer-email={user?.email}
                        >
                        </stripe-pricing-table> */}
        </>
      ) : (
        <>
          <stripe-pricing-table
            pricing-table-id="prctbl_1MzEkzSI0uPxDyzfcg9Cmxqe"
            publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
            client-reference-id={user?.userID}
            customer-email={user?.email}
          ></stripe-pricing-table>

          {/* For Testing */}
          {/* <stripe-pricing-table
                            pricing-table-id="prctbl_1LyWbESI0uPxDyzf7wxY3OBM"
                            publishable-key="pk_test_51LptSYSI0uPxDyzfPUojErRxeTrSngZ95JXzZlIYN3Wq9dCEO4JqnkqCUD8OPXnn6nb4xZLN6v9GN2dqG1LouPrT00jF5aLo71"
                            client-reference-id={user?.userID}
                            customer-email={user?.email}
                        >
                        </stripe-pricing-table> */}
        </>
      )}
    </>
  )
}

export default TopupPage
