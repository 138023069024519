import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button, Select } from "antd"
import { connect } from "react-redux"
import React from "react"
import { useDispatch } from "react-redux"
import { updateSelectedTiledata } from "../../data/redux/routestate/action"
import { useNavigate } from "react-router-dom"

function Topbar({ text, selectedTile }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <div
      className="ori-bg-white ori-flex ori-flex-ac ori-flex-jsb"
      style={{
        height: "63px",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.13)",
        fontSize: "14px",
        fontWeight: "600",
        padding: "0px 20px",
      }}
    >
      <span> {text}</span>
      {selectedTile?.title && (
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            if (selectedTile?.jobIdentifier == "longForm")
              navigate("/tool/blog" + "/list")
            else navigate("/tool/" + selectedTile?.path + "/list")
            dispatch(updateSelectedTiledata({ selectedTile: {} }))
          }}
        >
          All Options
        </Button>
      )}
    </div>
  )
}

const mapstatetoprops = (state) => {
  return {
    selectedTile: state.routestate.selectedTile,
  }
}

export default connect(mapstatetoprops)(Topbar)
