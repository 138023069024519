import { ConsoleSqlOutlined } from "@ant-design/icons"
import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "antd"
import React, { useEffect, useLayoutEffect } from "react"
import { Navigate } from "react-router-dom"
import SpinnerLoader from "../../components/SpinnerLoader"

function Login() {
  const { loginWithRedirect, loginWithPopup, isAuthenticated, isLoading } =
    useAuth0()

  useEffect(() => {
    localStorage.setItem("referralCode", window.location.search)

    if (window?.location?.search) {
      const qs = {}

      window?.location?.search
        ?.substring(1)
        ?.split("&")
        ?.forEach((a) => {
          let key = a.split("=")[0]
          let value = a.split("=")[1]
          return (qs[`${key}`] = value)
        })

      console.log("QS : ", qs)

      if (qs && qs.source) {
        localStorage.setItem("source", qs["source"])
      }
    }
  }, [])

  useEffect(() => {
    if (!isAuthenticated)
      loginWithRedirect({
        // authorizationParams: {
        // login_hint: "mk@oriserve.com"
        // }
      })
  }, [isAuthenticated])

  if (isAuthenticated) return <Navigate to="/tool" />

  return (
    // <div>
    //   <h2>User is logged out pls login again</h2>
    //   <Button
    //     onClick={() => {
    //       loginWithRedirect();
    //     }}
    //     type="primary"
    //   >
    //     Login
    //   </Button>
    // </div>
    <SpinnerLoader />
  )
}

export default Login
