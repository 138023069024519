const states = {
  page_details: {
    theme: "light", //light or dark
    is_internet_connected: navigator.onLine,
    checking_internet: false,
    lang: "en",
    modal_loading: false,
    page_loading: false,
    page_loading_text: "",
    generated: {
      content: false,
      route: "",
      output: {},
      LikeDislikeOptions: {},
    },
  },
}

export default states
