import { fetchDataAndProceed } from "../../utils/datautility"
import { USER_LOGGED_IN } from "./actionTypes"
import axios from "axios"

// const sample_body_viral_loop_reg = {
//   apiToken: "wWVb7z1vU3xNVt8ZCw356lbZAco",
//   params: {
//     event: "registration",
//     user: {
//       firstname: "John",
//       lastname: "Doe",
//       email: "john.doe@example.com",
//       extraData: {

//       },
//       consents: {
//         1: true
//       }
//     },
//     referrer: {
//       referralCode: "referralCode"
//     },
//     refSource: "refSource"
//   }
// };

const validateReferral = async (userData) => {
  try {
    console.log("validateReferral >> userData = ", userData)

    let qs_raw,
      obj = {}

    let data = localStorage.getItem("referralCode")

    if (data) {
      data
        ?.substring(1)
        ?.split("&")
        ?.forEach((qs) => {
          qs_raw = qs.split("=")
          obj[qs_raw[0]] = qs_raw[1]
        })
    }

    if (!obj.referralCode) return null

    let new_user = {
      referreeDetails: userData,
      referrersReferralCode: obj.referralCode,
    }

    const referral_res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/referral-reward`,
      new_user
    )

    if (referral_res.data && referral_res.data.rewarded) {
      console.log("Great user is rewarded...")
    }
  } catch (err) {
    console.error("err = ", err)
  }
}

export function fetchUserProfile() {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/profile`
    fetchDataAndProceed(
      {
        url,
      },
      (err, res) => {
        console.log(res, err)
        if (!res?.data?.data) {
          if (res?.data?.data == null) dispatch(signUp())
        } else {
          // validateReferral(res?.data?.data);

          dispatch({
            type: USER_LOGGED_IN,
            payload: res?.data?.data,
          })
        }
      }
    )
  }
}

export function signUp() {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/sign-up`
    fetchDataAndProceed(
      {
        url,
        method: "POST",
      },
      (err, res) => {
        let userData = res?.data?.data

        validateReferral(userData)

        dispatch({
          type: USER_LOGGED_IN,
          payload: userData,
        })
      }
    )
  }
}
