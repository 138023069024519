import { Button } from "antd"
import React from "react"
import { Navigate } from "react-router-dom"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import NavBar from "../NavBar"
import SpinnerLoader from "../SpinnerLoader"

function EmailVerification({ isLoading, isAuthenticated, user }) {
  console.log(isAuthenticated)
  if (isLoading) return <SpinnerLoader text="Loading Content. . ." />
  if (!isAuthenticated || user?.email_verified) return <Navigate to="/" />

  const resendLink = () => {
    fetchDataAndProceed(
      {
        url: "https://dev-u3qeva3b.jp.auth0.com/api/v2/jobs/verification-email",
        method: "POST",
        data: {
          user_id: user?.sub,

          client_id: "U7bEGzBYk6i2vWCo6dRrF77k9IUtkt9P",
          identity: {
            user_id: user?.sub,
            provider: "oauth2",
          },
        },
      },
      (err, res) => {
        console.log(res)
      }
    )
  }

  return (
    <>
      <NavBar />
      <div className="ori-flex ori-flex-ctr ori-flex-ac">
        <div
          className="ori-bg-white ori-box"
          style={{ width: "500px", padding: "20px" }}
        >
          <h1 style={{ textAlign: "center" }}>
            {" "}
            !!! Please verify your email !!!{" "}
          </h1>
          <img
            style={{ width: "100px", marginLeft: "38%" }}
            src="https://thumbs.gfycat.com/MasculinePrestigiousAppaloosa-max-1mb.gif"
          ></img>
          <br></br>

          <p style={{ textAlign: "center" }}>
            You are almost there! We have Sent a email verification link to{" "}
            <b>{user?.email}</b>
          </p>
          <br />

          <p style={{ textAlign: "center" }}>
            Just click on the link in that email to complete your signup.{" "}
            <b>Refresh this page after verification is completed.</b>If you
            don't see it you may need to <b>check your spam folder</b>.
          </p>
          <br></br>
          <p style={{ textAlign: "center" }}>
            Still can't find the email? Contact us @ contact@autorytr.com
          </p>
          <Button
            type="primary"
            style={{ marginLeft: "36%" }}
            onClick={resendLink}
          >
            Resend Link
          </Button>
        </div>
      </div>
    </>
  )
}

export default EmailVerification
