import { useAuth0 } from "@auth0/auth0-react"
import { Input } from "antd"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import NavBar from "../NavBar"

function UserProfile({ user }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div style={{ padding: "20px" }}>
      <div
        className="ori-bg-white ori-flex ori-flex-ac"
        style={{
          width: !isMobile ? "600px" : "100%",
          minHeight: "550px",
          border: "1px solid #d9d9d9",
          flexDirection: "column",
          margin: "auto",
          borderRadius: "15px",
          marginTop: "40px",
          padding: "15px",
        }}
      >
        <h1> Personal Details</h1>
        <div
          style={{
            width: "100px",
            height: "100px",
          }}
          className="ori-box "
        >
          <img src={user?.picture} style={{ width: "100%" }}></img>
        </div>
        <div style={{ width: "80%" }} className="ori-mrgn-t-20 ">
          <label>Name</label>
          <Input value={user?.nickname} disabled />
        </div>
        <div style={{ width: "80%" }} className="ori-mrgn-t-20 ">
          <label>Email</label>
          <Input value={user?.email} disabled />
        </div>
        <div style={{ width: "80%" }} className="ori-mrgn-t-20 ">
          <label>Plan Type</label>
          <Input value={user?.plan_type} disabled />
        </div>
      </div>
    </div>
  )
}

function mapstateToProps(state) {
  return {
    user: state.auth.user,
  }
}

export default connect(mapstateToProps)(UserProfile)
