import { Button, Input } from "antd"
import React, { useState, useEffect } from "react"
import { AiOutlineSmile } from "react-icons/ai"
import { AllTopics } from "../../data/config/constant"
import CardContainer from "../CardContainer"
import { AiOutlineMail, AiOutlineSearch } from "react-icons/ai"
import { CgNotes } from "react-icons/cg"
import { TfiAnnouncement } from "react-icons/tfi"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { updateSelectedTiledata } from "../../data/redux/routestate/action"

let AllTopics1 = AllTopics.flatMap((topic) => {
  if (topic.childs && topic.childsother)
    return [...topic.childs, ...topic.childsother]
  else return [topic]
})

console.log(AllTopics1)

function WelcomeScreen({ user }) {
  const [option, setOption] = useState("all")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  const [searchEnlarge, setSearchEnlarge] = useState(false)

  const [searchText, setSearchText] = useState("")

  const [topicsFilter, setTopicsFilter] = useState(AllTopics1)
  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const navigate = useNavigate()
  const options = [
    {
      title: "All",
      type: "component",
      path: "all",
    },
    // {
    //   title: 'Search',
    //   type: 'component',
    //   path: 'all',
    //   enlargeOnClick: true,
    //   type: "input"
    // },
    {
      title: "Email",
      type: "component",
      path: "email",
    },
    {
      title: "Social Media",
      type: "component",
      path: "socialmedia",
    },
    {
      title: "Content ",
      type: "component",
      path: "content",
    },
    {
      title: "Seo/Meta ",
      type: "component",
      path: "seo",
    },
    {
      title: "Audio/video ",
      type: "component",
      path: "transcribe",
    },
    {
      title: "Application",
      type: "component",
      path: "application",
    },
    {
      title: "Youtube",
      type: "component",
      path: "Youtube",
    },
  ]

  const popular = [
    {
      title: "Article and Blog",
      path: "blog",
      description: "Write great blogs by this tool",
      icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,

      background: "#BAE5F4",
    },
    {
      title: "Social Media",
      path: "socialplatform",
      description: "Write great ads for your social platforms",
      icon: <TfiAnnouncement size={36} />,
      background: "#CCF0BF",
    },
    {
      title: "Email Generator",
      path: "emailGenerator",
      description: "Write great emails",
      icon: <AiOutlineMail style={{ fontSize: "36px", color: "#F04B4B" }} />,
      background: "#E2D2FE",
    },
  ]
  return (
    <div>
      <div
        className="ori-flex ori-flex-ctr ori-flex-ac"
        style={{ flexDirection: "column", padding: "20px 20px 0px" }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: !isMobile ? "30px" : "25px",
            textAlign: "center",
          }}
        >
          {user?.given_name ? user.given_name : user.nickname}! What exactly you
          have in mind ✍️.
        </span>

        <span style={{ textAlign: "center" }}>
          Begin with selecting the content type from the options below.
        </span>

        <br />

        <Input
          id="search-input"
          value={searchText}
          style={{
            borderRadius: "1rem",
            margin: "0 1.3rem 0 2rem",
            width: !isMobile ? "60%" : "100%",
            textAlign: "center",
          }}
          placeholder="Search Your favourite Service Here"
          onBlur={() => setSearchEnlarge(false)}
          onInput={(e) => {
            const val = e.target.value.toLowerCase()

            setSearchText(val)

            if (!val) setTopicsFilter(AllTopics1)

            const topicsMatched = AllTopics1.filter((topic) => {
              if (
                topic.title.toLowerCase().includes(val) ||
                topic.description.toLowerCase().includes(val)
              ) {
                return true
              }
            })

            setTopicsFilter(topicsMatched)
          }}
        />
        <br />
      </div>

      <div className="ori-flex ori-flex-ctr ori-flex-ac ori-flex-wrap">
        {options.map((item) => {
          // if (item?.type === "input") {
          //   return (
          //     <>

          //       {
          //         searchEnlarge ?
          //           <Input
          //             id="search-input"
          //             value={searchText}
          //             style={{ borderRadius: "1rem", margin: "0 1.3rem 0 2rem", width: "60%" }}
          //             placeholder="Search Your Favourate Service"
          //             onBlur={() => setSearchEnlarge(false)}
          //             onInput={(e) => {
          //               const val = e.target.value.toLowerCase();

          //               setSearchText(val);

          //               if (!val) setTopicsFilter(AllTopics1);

          //               const topicsMatched = AllTopics1.filter((topic) => {
          //                 if (topic.title.toLowerCase().includes(val) || topic.description.toLowerCase().includes(val)) {
          //                   return true;
          //                 }
          //               })

          //               setTopicsFilter(topicsMatched);

          //             }}
          //           />
          //           :
          //           <AiOutlineSearch size={25}
          //             onClick={() => {
          //               setSearchEnlarge(true);
          //             }}
          //             style={{ cursor: "pointer", borderRadius: '30px', marginLeft: '10px' }}
          //           />
          //       }

          //     </>
          //   );
          // }

          return (
            <Button
              title="Search"
              type={item.path == option ? "primary" : ""}
              style={{
                borderRadius: "20px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              onClick={() => setOption(item.path)}
            >
              {item.title}
            </Button>
          )
        })}
      </div>

      <div style={{ padding: "0px 20px 20px" }}>
        {!isMobile && (
          <div>
            <span style={{ fontSize: "16px", marginLeft: "10px" }}>
              Popular Category
            </span>
            <div
              style={{
                display: isMobile ? "flex" : "",
                justifyContent: isMobile ? "center" : "",
                flexWrap: "wrap",
                marginBottom: "10px",
                alignItems: "flex-start",
              }}
              className="ori-flex "
            >
              {popular.map((item) => {
                return (
                  <div
                    onClick={() => {
                      dispatch(
                        updateSelectedTiledata({
                          selectedTile: {},
                        })
                      )
                      navigate(item.path + "/list")
                    }}
                    style={{
                      minWidth: "300px",
                      width: "31%",
                      margin: "0px 10px",
                    }}
                  >
                    <CardContainer item={item} background={item.background} />
                  </div>
                )
              })}
            </div>
          </div>
        )}

        <span style={{ fontSize: "16px", marginLeft: "10px" }}>All Topics</span>
        <div
          style={{
            display: isMobile ? "flex" : "",
            justifyContent: isMobile ? "center" : "",
            flexWrap: "wrap",
            // maxHeight: 'calc(100vh - 425px)',
            overflowY: "auto",
          }}
          className="ori-flex "
        >
          {topicsFilter
            .filter((item) => {
              if (option == "all") return true
              if (option === "Youtube") return item.title.includes(option)
              else return option === item.type
            })
            .map((item) => {
              return (
                <div
                  onClick={() => {
                    if (item?.jobIdentifier === "longForm") {
                      navigate(item.path)
                    } else navigate(item.path + "/" + item.route)
                    const payload = { ...item }
                    payload.icon = ""
                    dispatch(
                      updateSelectedTiledata({
                        selectedTile: payload,
                      })
                    )
                  }}
                  style={{
                    minWidth: "300px",
                    width: "31%",
                    margin: "0px 10px",
                  }}
                >
                  <CardContainer item={item} />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default WelcomeScreen
