import React from "react"
import { Bars } from "react-loader-spinner"

function SpinnerLoader({ text, opacity = "0.5" }) {
  return (
    <div
      className="ori-flex ori-flex-ac ori-flex-ctr ori-full-view-h ori-bg-white"
      style={{
        border: "1px solid black",
        position: "absolute",
        top: 0,
        zIndex: "99999",
        width: "100vw",
        opacity,
      }}
    >
      <div
        className="ori-flex ori-flex-ctr ori-flex-ac"
        style={{ flexDirection: "column" }}
      >
        <Bars
          height="80"
          width="80"
          color="#7000f4"
          ariaLabel="bars-loading"
          visible={true}
        />
        <p style={{ color: "#7000f4" }}>{text}</p>
      </div>
    </div>
  )
}

export default SpinnerLoader
