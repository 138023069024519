import { UPDATE_PAGE_STATE } from "./actionTypes"
import states from "./state"

const page_details = (state = states.page_details, action) => {
  switch (action.type) {
    case UPDATE_PAGE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return { ...state }
  }
}

export default page_details
