import { useCallback, useEffect, useContext } from "react"
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom"

export default function usePrompt(message, when = true) {
  const { navigator } = useContext(NavigationContext)
  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message)
    return confirm
  }, [message])

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push;

    navigator.push = (...args) => {
      const result = confirmExit();
      if (!result) {
        navigator.stop();
        return
      }
      push(...args)
    }

    let unblock;

    const handleBlockedNavigation = (location) => {
      if (when) {
        return message
      } else {
        return true
      }
    }

    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault()
        event.returnValue = message
      }
    };

    const handlePopstate = () => {
      const confirmed = confirmExit()
      if (confirmed) {
        unblock()
      }
    };

    if (when) {
      window.addEventListener("beforeunload", handleBeforeUnload)
      window.history.pushState(null, null, window.location.href)
      window.addEventListener("popstate", handlePopstate)
      unblock = window.history.back.bind(window.history)
      unblock.block = (message) => handleBlockedNavigation;
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload)
      window.removeEventListener("popstate", handlePopstate)
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
      window.removeEventListener("popstate", handlePopstate)
      navigator.push = push;
    };
  }, [confirmExit, navigator, message, when])

  return confirmExit
}
