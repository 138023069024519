import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import SpinnerLoader from "../SpinnerLoader"

function SubscriptionCheck({ isSubscribed }) {
  if (isSubscribed === false) return <Navigate to="/tool/pricing" />

  if (isSubscribed === true) return <Outlet />

  return <SpinnerLoader text="checking subsciption. . ." />
}

export default SubscriptionCheck
