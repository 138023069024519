import "./App.css"
import Sider from "../components/Sider"
import { createContext, useEffect, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import Component3 from "../components/Component3"
import History from "../components/History"
import SocialMedia from "../components/SocialMedia"
import BlogGenerator from "../components/BlogGenerate"
import SeoMeta from "../components/SeoMeta"
import EmailGenerator from "../components/EmailGenerator"
import WelcomeScreen from "../components/WelcomeScreen"
import RedeemCode from "../components/RedeemCode"
import Appweb from "../components/appweb"
import { AiOutlineMail } from "react-icons/ai"
import { TfiAnnouncement } from "react-icons/tfi"
import { CgNotes } from "react-icons/cg"
import { BsSoundwave } from "react-icons/bs"
import { RiArticleLine } from "react-icons/ri"
import { FiRefreshCw } from "react-icons/fi"
import { AiOutlineSave } from "react-icons/ai"
import { BsCurrencyDollar } from "react-icons/bs"
import { EditorState } from "draft-js"
import { BsSearch } from "react-icons/bs"
import { MdLibraryBooks, MdPinInvoke } from "react-icons/md"

import Post from "../components/Posts"

import { Avatar, Input, Button, message } from "antd"
import UserProfile from "../components/UserProfile"
import PricingPage from "../components/PricingPage"
import Buymore from "../components/Buymore"
import SocialAccounts from "../components/SocialAccounts"
import SocialLogin from "../components/SocialLogin"
import SubscriptionCheck from "../components/SubsciptionCheck"
import Referral from "../components/Referrals"
import ArticleGenerator from "../components/ArticleGenetrator"
import Appreview from "../components/AppReview"
import NavBarMobile from "../components/NavbarMobile"

import AutorytrWelcomeAppsumo from "../data/assets/AutorytrWelcomeAppsumo.png"
import Logo from "../data/assets/Logo.png"
import { fetchDataAndProceed } from "../data/utils/datautility"
import TopupPage from "../components/TopupPage"
import PaymentSuccess from "../components/PaymentSuccess"

export const EditorContext = createContext()

function AppContainer({ user }) {
  const [siderModule, setSiderModule] = useState([
    {
      title: "Library",
      type: "component",
      path: "/tool",

      icon: <MdLibraryBooks size={26} color="white" />,
    },

    {
      title: "Email",
      type: "component",
      path: "/tool/emailGenerator/list",
      parent: "Library",
      icon: <AiOutlineMail size={16} color="white" />,
    },
    {
      title: "Social Media",
      type: "component",
      path: "/tool/socialplatform/list",
      parent: "Library",
      icon: <TfiAnnouncement size={16} color="white" />,
    },
    {
      title: "Content ",
      type: "component",
      path: "/tool/blog/list",
      parent: "Library",
      icon: <CgNotes size={16} color="white" />,
    },
    {
      title: "Product ",
      type: "component",
      path: "/tool/seo/list",
      parent: "Library",
      icon: <BsSearch size={16} color="white" />,
    },
    {
      title: "Audio/video ",
      type: "component",
      path: "/tool/transcribe",
      parent: "Library",
      icon: <BsSoundwave size={16} color="white" />,
    },
    {
      title: "Application",
      type: "component",
      path: "/tool/appweb/list",
      parent: "Library",
      icon: <RiArticleLine size={16} color="white" />,
    },
    // {
    //   title: "",
    //   type: "divider"
    // },

    {
      title: "Saved",
      type: "component",
      path: "/tool/posts",
      icon: <AiOutlineSave size={26} color="white" />,
    },
    {
      title: "Job History",
      type: "component",
      path: "/tool/history",
      icon: <FiRefreshCw size={26} color="white" />,
    },
  ])

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [source, setSource] = useState("")
  const [code, setCode] = useState("")

  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // calling redeemcode specially for appsumo lings
  const redeemCode = () => {
    if (!code) {
      message.error("Please enter code in order to avail the rewards...")
      return
    }

    fetchDataAndProceed(
      {
        url: process.env.REACT_APP_BASE_URL + "/operations/coupon",
        method: "POST",
        data: {
          code,
        },
      },
      (err, res) => {
        console.log(err, res)
        setCode("")
        if (!err && res) {
          message.success(
            "Code Applied! Please refresh your page and then check quota"
          )
          localStorage.removeItem("source")
          setSource("")
        } else {
          message.error(err?.response?.data?.message)
        }
      }
    )
  }

  useEffect(() => {
    const source = localStorage?.getItem("source")

    if (source) {
      setSource(source)
    }

    if (user?.plan_type == "Trial") {
      setSiderModule([
        ...siderModule,
        // {
        //   title: "Upgrade plan",
        //   type: "component",
        //   path: "/tool/pricing",
        //   icon: <BsCurrencyDollar size={26} color="white" />
        // },
        // {
        //   title: "Referral",
        //   type: "component",
        //   path: "/tool/referral",
        //   icon: <AiOutlineUsergroupAdd size={26} color="white" />
        // },
        // {
        //   title: "Redeem code",
        //   type: "component",
        //   path: "/tool/redeem",
        //   icon: <TbTicket size={26} color="white" />
        // }
      ])
    } else if (user?.plan_type == "Lifetime") {
      setSiderModule([
        ...siderModule,
        {
          title: "Buy more",
          type: "component",
          path: "/tool/buymore",
          icon: <BsCurrencyDollar size={26} color="white" />,
        },
        // {
        //   title: "Referral",
        //   type: "component",
        //   path: "/tool/referral",
        //   icon: <AiOutlineUsergroupAdd size={26} color="white" />
        // },
        // {
        //   title: "Redeem",
        //   type: "component",
        //   path: "/tool/redeem",
        //   icon: <TbTicket size={26} color="white" />
        // }
      ])
    } else if (user?.plan_type) {
      setSiderModule([
        ...siderModule,
        // {
        //   title: "Manage billing",
        //   type: "link",
        //   icon: <MdPinInvoke size={26} color="white" />
        // },
        {
          title: "Buy more",
          type: "component",
          path: "/tool/buymore",
          icon: <BsCurrencyDollar size={26} color="white" />,
        },
        // {
        //   title: "Referral",
        //   type: "component",
        //   path: "/tool/referral",
        //   icon: <AiOutlineUsergroupAdd size={26} color="white" />
        // },
        // {
        //   title: "Redeem code",
        //   type: "component",
        //   path: "/tool/redeem",
        //   icon: <TbTicket size={26} color="white" />
        // }
      ])
    }
    // if (process.env.REACT_APP_BETA_FEATURES)
    //   setSiderModule([
    //     ...siderModule,
    //     {
    //       title: "Youtube audio Transcribe",
    //       type: "component",
    //       path: "/tool/component3",
    //     },
    //   ]);
  }, [])

  return (
    <EditorContext.Provider value={{ editorState, setEditorState }}>
      <div className="App">
        {/* welcome page to onboard appsumousers */}
        {source && source === "appsumo" ? (
          <>
            <div className="appsumo-welcome-modal">
              <h1
                onClick={() => {
                  localStorage.removeItem("source")
                  setSource("")
                }}
                style={{
                  color: "white",
                  position: "absolute",
                  top: "2%",
                  right: "3%",
                  cursor: "pointer",
                }}
              >
                &times;
              </h1>

              <div className="appsumo-welcome-modal-container">
                <div class="container-header">
                  <img src={Logo} style={{ width: "7%" }} />
                  <span style={{ textAlign: "center", color: "indigo" }}>
                    Welcome To Autorytr
                  </span>
                </div>

                <div className="container-content">
                  <div>
                    <h1 style={{ fontSize: "3rem", color: "red" }}>
                      Welcome, Sumo-lings!
                    </h1>
                    <h3 style={{ margin: "5% 0" }}>
                      Thank you, for purchasing the LifeTime Deal on AppSumo.
                    </h3>
                    <h1>
                      Please enter your unique code below to unlock the benefits
                    </h1>
                  </div>

                  <div>
                    <img
                      src={AutorytrWelcomeAppsumo}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "2%",
                    justifyContent: "space-between",
                  }}
                >
                  <Input
                    style={{ flex: 0.8 }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter Your App Sumo Code"
                  ></Input>

                  <Button
                    style={{ flex: 0.16 }}
                    onClick={redeemCode}
                    value={"large"}
                    type="primary"
                  >
                    Redeem
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {!isMobile && (
          <div className="app-left">
            <Sider siderModule={siderModule} user={user} />
          </div>
        )}
        <div className="app-right">
          {isMobile && <NavBarMobile siderModule={siderModule} />}

          <Routes>
            <Route
              element={
                <SubscriptionCheck isSubscribed={user?.subscription_status} />
              }
            >
              <Route path="/" element={<WelcomeScreen user={user} />} />
              <Route
                path="blog/longForm/:step"
                element={<ArticleGenerator />}
              />

              <Route path="emailGenerator/:type" element={<EmailGenerator />} />
              <Route path="socialplatform/:type" element={<SocialMedia />} />
              <Route path="blog/:type" element={<BlogGenerator />} />
              <Route path="seo/:type" element={<SeoMeta />} />
              <Route path="appweb/:type" element={<Appweb />} />

              <Route path="transcribe" element={<Component3 />} />

              <Route path="buymore" element={<Buymore user={user} />} />
              <Route path="socialaccounts" element={<SocialAccounts />} />

              <Route path="social-login" element={<SocialLogin />} />
            </Route>
            <Route path="appreview" element={<Appreview />} />
            <Route path="history" element={<History />} />

            <Route path="profile" element={<UserProfile />} />
            <Route path="posts" element={<Post />} />
            <Route path="pricing" element={<PricingPage user={user} />} />
            <Route path="topup" element={<TopupPage user={user} />} />
            <Route path="referral" element={<Referral user={user} />} />
            <Route path="redeem" element={<RedeemCode />} />
            <Route
              path="payment_success"
              element={<PaymentSuccess user={user} />}
            />
          </Routes>
        </div>
      </div>
    </EditorContext.Provider>
  )
}

export default AppContainer
