import axios from "axios"
import { useNavigate } from "react-router-dom"
import { updatePageState } from "../redux/page_details/action"
// import { updatePageState } from "../redux/page_details/actions";
import store from "../redux/store"

export const getDataFromLocalStorage = (key) => {
  return localStorage.getItem(key)
}

const getHeaders = (content_type) => {
  let headers = { "Content-Type": content_type }
  if (getDataFromLocalStorage("token"))
    headers.Authorization = `bearer ${getDataFromLocalStorage("token")}`
  headers["ngrok-skip-browser-warning"] = "8"
  //headers["Access-Control-Allow-Origin"] = "*";
  return headers
}

export const clearDataFromLocalStorage = () => {
  localStorage.clear()
}

export const fetchDataAndProceed = (
  {
    url,
    method = "GET",
    data = {},
    timeout = 20000,
    content_type = "application/json",
    responseType,
    axios_args = {},
    autho = false,
    loader = true,
    loader_text = "loading",
    botBuilder,
  } = {},
  callback
) => {
  let params = {}
  let payload = {}
  console.log(data)

  params = method === "GET" ? data : {}
  payload = method !== "GET" ? data : {}

  store.dispatch(
    updatePageState({ page_loading: loader, page_loading_text: loader_text })
  )

  axios({
    url,
    method,
    timeout,
    responseType,
    params: params,
    data: payload,
    headers: getHeaders(content_type),
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 412,
    ...axios_args,
  })
    .then((response) => {
      store.dispatch(
        updatePageState({ page_loading: false, page_loading_text: "" })
      )
      if (callback) callback(null, response)
    })
    .catch((error) => {
      store.dispatch(
        updatePageState({ page_loading: false, page_loading_text: "" })
      )

      if (
        (error?.response?.status == 401 || error?.code == "ERR_NETWORK") &&
        autho
      ) {
        window.location.reload()
      }
      console.log(error)
      if (callback) callback(error, null)
    })
}

export const fetchUrlParams = (searchString) => {
  const obj = {}

  searchString.split("&")?.map((item) => {
    obj[item.split("=")[0]] = item.split("=")[1]
  })

  return obj
}

export const uid = () => {
  const date = new Date()

  const id = date.getTime() + "" + Math.round(Math.random() * (10000 - 0))

  return id
}

export const wordCount = (variation) => {
  let cnt = 0
  variation?.split("\n")?.forEach((item) => {
    if (item) {
      let arr = item?.split(" ")
      cnt += arr.length
    }
  })

  return cnt;
};

export const loadCleverTapScript = (
  FILE_URL,
  token,
  async = true,
  type = "text/javascript"
) => {
  try {
    window.clevertap = {
      event: [],
      profile: [],
      account: [],
      onUserLogin: [],
      notifications: [],
      privacy: [],
    }
    window.clevertap.account.push({ id: token })
    window.clevertap.privacy.push({ optOut: false }) //set the flag to true, if the user of the device opts out of sharing their data
    window.clevertap.privacy.push({ useIP: false}) //set the flag to true, if the user agrees to share their IP data
    const scriptEle = document.createElement("script")
    scriptEle.type = type
    scriptEle.async = async
    scriptEle.src = FILE_URL
    document.head.appendChild(scriptEle)
  } catch (error) {
    console.log("clevertapLoadScript Error", error)
  }
}
