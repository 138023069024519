import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getDataFromLocalStorage } from "../../data/utils/datautility";
import { CleverTap } from "../../data/CleverTap";
import { Mixpanel } from "../../data/MixPanel";
import NavBar from "../NavBar";

function Buymore({ user, isAuthenticated }) {
  const [country, setCountry] = useState(getDataFromLocalStorage("country"))

  useEffect(()=>{
    if (user.userId) {
      CleverTap.track("Buy More Page Frontend", {
        Route: window.location.href,
        UserIdentify: user.userId,
      })
      Mixpanel.identify(user.userId)
      Mixpanel.track("Buy More Page")
    } 
  },[user])

  if (user?.subscription_status && user?.plan_type === "Trial")
    return <Navigate to="/tool" />

  return (
    <>
      {country == "IN" ? (
        <stripe-pricing-table
          pricing-table-id="prctbl_1LyvsjSI0uPxDyzfXb9mRVRY"
          publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
          client-reference-id={user?.userID}
          customer-email={user?.email}
        ></stripe-pricing-table>
      ) : (
        <stripe-pricing-table
          pricing-table-id="prctbl_1Lyvs0SI0uPxDyzf7wqqp7le"
          publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
          client-reference-id={user?.userID}
          customer-email={user?.email}
        ></stripe-pricing-table>
      )}
    </>
  )
}

export default Buymore
