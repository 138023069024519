export const debounced = (func, delay) => {
  let timerId
  return (...args) => {
    if (timerId) clearTimeout(timerId)
    timerId = setTimeout(() => {
      func(...args)
      timerId = null
    }, delay)
  }
}

export const log = (...args) => {
  console.log(...args)
}

export const METHOD_TYPES = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
}

export const languages_for_content_generator = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Turkish",
    label: "Turkish",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
  },
  {
    value: "Thai",
    label: "Thai",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
  },
  {
    value: "Bengali",
    label: "Bengali",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Urdu",
    label: "Urdu",
  },
  {
    value: "Punjabi",
    label: "Punjabi",
  },
]

export const JobType = {
  email: "Email Generation",
  facebook: "Facbook Ad",
  insta: "Instagram Ad",
  tweet: "Tweet Generator",
  linkedin: "LinkedIn Post",
  blogTitle: "Blog Title Generator",
  blogIdeas: "Blog Idea Generator",
  blogIntro: "Blog Intro Generator",
  blogHeadlineExpander: "Blog headline Expander",
  blogOutline: "Blog outline Generator",
  blogConclusion: "Blog Conclusion",
  seoMeta: "Meta description",
  headline: "Headline Generator",
  valuePropostion: "Value Proposition",
  productDescription: "Product description",
  poetry: "Poetry",
  transcribe: "Audio/video Transcription",
  onboardApp: "Onboarding Messages",
  titleApp: "Website/App Title",
  descriptionApp: "Website/App Description",
  marketingEmail: "Marketing Email",
  longForm: "Long Form Articles",
  shortForm: "Short Form Articles",
  ytidea: "Youtube Video Idea",
  ytscriptoutline: "Youtube Video Script Outline",
  ythookintro: "Youtube Video Hook Intro",
}
