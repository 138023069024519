let env_check = process.env.REACT_APP_CLEVERTAP_ENABLE && process.env.REACT_APP_CLEVERTAP_TOKEN

let actions = {
onUserLogin: (data) => {
    if (env_check) window.clevertap.onUserLogin.push({
      "Site" : data
    })
  },
  track: (eventName, data) => {
    if (env_check) window.clevertap.event.push(eventName, data)
  },
}
export let CleverTap = actions