import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { isRejected } from "@reduxjs/toolkit"
import { Button, Divider, Input, message, Upload } from "antd"
import Dragger from "antd/lib/upload/Dragger"
import React, { useState, useSyncExternalStore } from "react"
import { useDispatch } from "react-redux"
import { fetchUserProfile } from "../../data/redux/auth/action"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import "./comp3.css"

function Component3() {
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const dispatch = useDispatch()

  function getDuration(file) {
    return new Promise(function (resolve, reject) {
      try {
        var reader = new FileReader()
        var audio = new Audio()
        reader.onload = function (e) {
          audio.src = e.target.result
          audio.addEventListener(
            "loadedmetadata",
            function () {
              // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
              var duration = audio.duration

              // example 12.3234 seconds
              resolve(duration)

              // Alternatively, just display the integer value with
              // parseInt(duration)
              // 12 seconds
            },
            false
          )
        }

        reader.readAsDataURL(file)
      } catch (e) {
        reject(e)
      }
    })
  }

  const uploadFileOrUrl = async () => {
    const duration = await getDuration(fileList[0])

    if (isNaN(duration)) return message.error("File is corrupted")
    const formdata = new FormData()

    fileList.forEach((file) => {
      formdata.append("media", file)
    })
    formdata.append("userId", "1")
    formdata.append("jobIdentifier", "transcribe")
    formdata.append("duration", duration)
    setUploading(true)
    const url = process.env.REACT_APP_BASE_URL + "/jobs/autoryt"
    fetchDataAndProceed(
      {
        url,
        method: "POST",
        data: formdata,
        timeout: 1000000,
        content_type: "multipart/form-data",
      },
      (err, res) => {
        console.log(err, res)
        if (!err) {
          message.success(
            res?.data?.message +
              " Please check your job history for the status of the transcription.",
            12000
          )
          dispatch(fetchUserProfile())
        } else {
          message.error(err?.response?.data?.message)
        }
        setUploading(false)
        setFileList([])
      }
    )
  }

  const props = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      console.log(file)
      if (file.size > 10000000)
        message.error("File size greater than 10 MB not allowed")
      else setFileList([file])
      return false
    },
    fileList,

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    },
  }

  return (
    <div className="component3 ori-flex ori-flex-jsb">
      <div className="c3-left ori-flex ori-flex-ac ori-flex-ctr">
        <div
          className="ori-bg-white ori-box-border-radius ori-padding-20"
          style={{
            width: "800px",
            border: "1px solid #d9d9d9",
          }}
        >
          <InfoCircleOutlined />
          <span style={{ marginLeft: "5px" }}>
            Currently, only english audio transcription is allowed.
            Transcription might take upto 5 mins. After submitting your file, go
            to the job history section to check the output.
            <br />
            <br />
            Supported Files: m4a, mp3, webm, mp4, mpga, wav, mpeg <br />
            Max File Size: 10 MB <br />
          </span>
          <br />
          <h2>File Upload</h2>
          <div className="ori-mrgn-tb-20">
            <Dragger {...props} accept=".mp3,.mp4,.webm,.wav">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibit from uploading
                company data or other band files
              </p>
            </Dragger>
          </div>
          {/* <Divider>OR</Divider>
          <div className="ori-mrgn-tb-20 ">
            <label>File Url</label>
            <Input
              style={{ borderRadius: "5px", height: "40px" }}
              disabled={fileList.length > 0}
              value={fileUrl}
              onChange={(e) => setFileUrl(e.target.value)}
            />{" "}
          </div> */}
          <Button
            type="primary"
            onClick={uploadFileOrUrl}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              width: "100%",
            }}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Component3
