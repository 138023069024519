import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom"
import AppContainer from "./AppContainer"
import Login from "./AppContainer/AppLogin"
import Buymore from "./components/Buymore"
import EmailVerification from "./components/EmailVerification"
import Pricing from "./components/PricingPage"
import ProtectedRoute from "./components/ProtectedRoute"
import SpinnerLoader from "./components/SpinnerLoader"
import SubscriptionCheck from "./components/SubsciptionCheck"
import UserProfile from "./components/UserProfile"
import { fetchUserProfile } from "./data/redux/auth/action"
import ReactGA from "react-ga"
import { Button, notification, message, Modal } from "antd"
import { AllTopics } from "./data/config/constant"
import AppreviewModal from "./data/assets/AppreviewModal.png"

import OneSignalReact from "react-onesignal"

import {
  fetchDataAndProceed,
  getDataFromLocalStorage,
  uid,
} from "./data/utils/datautility"
import { CleverTap } from "./data/CleverTap"
import PricingPage from "./components/PricingPage"
import { JobType } from "./data/utils/common"
import { updatePageState } from "./data/redux/page_details/action"
ReactGA.initialize("360298588") // old id : UA-245702566-1
// ReactGA.pageview(window.location.pathname + window.location.search)

function App({ auth, page_loading, generated }) {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user, logout } =
    useAuth0()

  const navigate = useNavigate()

  //console.log(user, "sasa")
  const dispatch = useDispatch()
  const [modal, setShowModal] = useState(false)

  useEffect(() => {
    if (generated.content) {
      if (
        auth?.appSumoReview?.showAppSumoReviewPopUp ||
        !auth.hasOwnProperty("appSumoReview")
      )
        if (generated?.LikeDislikeOptions?.liked)
          setTimeout(() => {
            setShowModal(true)
            dispatch(
              updatePageState({
                generated: {
                  ...generated,
                  route: "",
                  content: false,
                },
              })
            )
          }, 1000)
        else if (
          generated.route !== window.location.pathname &&
          !generated?.LikeDislikeOptions?.liked &&
          !generated?.LikeDislikeOptions?.disliked
        )
          setTimeout(() => {
            setShowModal(true)
            dispatch(
              updatePageState({
                generated: {
                  ...generated,
                  route: "",
                  content: false,
                  LikeDislikeOptions: {},
                },
              })
            )
          }, 4000)
    }
  }, [
    auth?.appSumoReview?.showAppSumoReviewPopUp,
    window.location.pathname,
    dispatch,
    generated.content,
    generated.route,
    generated.LikeDislikeOptions?.liked,
    generated.LikeDislikeOptions?.disliked,
    generated,
  ])

  useEffect(() => {
    if (!localStorage.getItem("eventid")) {
      localStorage.setItem("eventid", uid())
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
    if (isAuthenticated) {
      ;(async () => {
        const token = await getAccessTokenSilently()
        console.log(token)
        localStorage.setItem("token", token)
        dispatch(fetchUserProfile())
      })()
    }

    fetchDataAndProceed(
      {
        url: "https://ipinfo.io?token=1d831d07eb3709",
        autho: false,
      },
      (err, res) => {
        console.log(res, "countryyyy")
        localStorage.setItem("country", res.data.country)
      }
    )
  }, [isAuthenticated])

  useEffect(() => {
    console.log("I am use effect for sse. dependent on auth")

    if (auth) {
      var events = new EventSource(
        `${
          process.env.REACT_APP_BASE_URL
        }/jobs/jobstream?id=${localStorage.getItem("eventid")}`
      )

      events.addEventListener("open", () => {
        console.log("Connected to server for SSE...")
      })

      events.addEventListener("message", (event) => {
        if (event) {
          const jobId = event.lastEventId
          const data = JSON.parse(event.data)

          if (
            data &&
            data.userId &&
            auth &&
            auth.userId &&
            data.userId === auth.userId
          ) {
            message.destroy()
            let AllTopicsIncChilds = AllTopics?.flatMap((topics) => {
              if (topics.childs) return topics.childs
              else return topics
            })

            let job = AllTopicsIncChilds.find(
              (topic) => topic.jobIdentifier === data.jobIdentifier
            )
            const urlRoute = window.location.pathname.split("/")
            let formType =
              job.jobIdentifier === "longForm"
                ? urlRoute.includes(job.jobIdentifier)
                : urlRoute.includes(job.route)
            if (!(job && formType)) {
              notification.info({
                duration: 8,
                message: `The job with ${
                  JobType[data.jobIdentifier]
                } is completed`,
                btn: (
                  <Button
                    type="primary"
                    onClick={() => {
                      notification.destroy()
                      navigate("/tool/history", {
                        state: { __id: data.output[0]?._id },
                      })
                    }}
                  >
                    See Job history
                  </Button>
                ),
                description: (
                  <>
                    <p>You may check your completed job in your job history.</p>
                  </>
                ),
              })
            }
          }
        }
      })

      events.addEventListener("error", (err) => {
        console.log("Error while listening to SSE err = ", err)
      })
    }

    return () => {
      events?.removeEventListener("error", () => {})
      events?.removeEventListener("message", () => {})
      events?.removeEventListener("open", () => {})
    }
  }, [auth])

  // No dependency will run only for one signal web push notification
  useEffect(() => {
    ;(async () => {
      try {
        await OneSignalReact.init({
          appId: process.env.REACT_APP_ONE_SIGNAL_ID,
          allowLocalhostAsSecureOrigin: true,
        })

        const res = await OneSignalReact.showSlidedownPrompt()

        console.log("response: ", res)
      } catch (err) {
        console.log("Error: ", err)
      }
    })()
  }, [])

  useEffect(()=>{
    if(auth){
    CleverTap.onUserLogin({
      Name: auth.nickName,
      Identity: auth.userId,          
     })
    }
  }, [auth])

  const handleOk = () => {
    window.open("https://appsumo.com/products/autorytr/#reviews", "_blank")
    setShowModal(false)
  }

  return (
    <div>
      {page_loading.page_loading && (
        <SpinnerLoader text={page_loading.page_loading_text} />
      )}
      <Routes>
        <Route path="/" element={<Login />}></Route>

        <Route
          path="/verifyEmail"
          element={
            <EmailVerification
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              user={auth}
            />
          }
        />
        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              user={auth}
              logout={logout}
            />
          }
        >
          <Route path="/profile" element={<UserProfile />} />
          {/* <Route path="/pricing/" element={<Pricing user={auth} />} />
          <Route path="/buymore" element={<Buymore user={auth} />} /> */}

          <Route path="/tool/*" element={<AppContainer user={auth} />} />

          {/* <Route
            path="/tool/pricing"
            element={<PricingPage user={auth} />}
          /> */}
        </Route>
      </Routes>
      <Modal
        open={modal}
        onCancel={() => setShowModal(false)}
        bodyStyle={{ padding: 0, backgroundImage: `url(${AppreviewModal})` }}
        maskClosable={false}
        footer={null}
        destroyOnClose
      >
        <div className="ori-pad-10 ori-mrgn-l-10">
          <p
            style={{ marginBottom: "10px", fontSize: "20px", fontWeight: 600 }}
          >
            Help us improve & serve you better!
          </p>
          <p>
            Drop a review for us on Appsumo & we'd be beyond grateful for your
            support.
          </p>
          <p>
            You can unlock additional <strong>2000 FREE Words</strong> by simply
            sending us a screenshot at{" "}
            <a href="mailto: contact@autorytr.com">contact@autorytr.com </a> 🥳
          </p>
          <p>You'd make our day, week, month - heck, even our year! 😃</p>
          <div className="ori-flex ori-flex-jc ori-pad-10 ori-t-mrgn-10">
            <Button
              className="ori-r-mrgn-15"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk}>
              Review on AppSumo
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

function mapstateToProps(state) {
  return {
    auth: state.auth.user,
    page_loading: state.page_details,
    generated: state.page_details.generated,
  }
}

export default connect(mapstateToProps)(App)
