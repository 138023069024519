import { useDispatch, connect } from "react-redux";
import React, { useEffect, useRef, useState, useContext } from "react"
import {
  Button,
  Input,
  Select,
  message,
  Skeleton,
  notification,
  Form,
  Modal,
} from "antd"
import { fetchUserProfile } from "../../data/redux/auth/action"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import "./comp2.css"
import { useParams } from "react-router-dom"
import { EditorState, ContentState } from "draft-js"
import { languages_for_content_generator } from "../../data/utils/common"
import { updatePageState } from  "../../data/redux/page_details/action"

import LikeDislike from "../History/LikeDislike"
import { AllTopics } from "../../data/config/constant"
import { updateSelectedTiledata } from "../../data/redux/routestate/action"
import { EditorContext } from "../../AppContainer"
import AppreviewModal from "../../data/assets/AppreviewModal.png";

const linebreak = "*******************************"

const toneOptions = [
  {
    value: "Formal",
    label: "Formal",
  },
  {
    value: "Informal",
    label: "Informal",
  },
  {
    value: "Humorous",
    label: "Humorous",
  },
  {
    value: "Serious",
    label: "Serious",
  },
  {
    value: "Optimistic",
    label: "Optimistic",
  },
  {
    value: "Motivating",
    label: "Motivating",
  },
  {
    value: "Respectful",
    label: "Respectful",
  },
  {
    value: "Assertive",
    label: "Assertive",
  },
  {
    value: "Conversational",
    label: "Conversational",
  },
]
const audienceOptions = [
  {
    value: "Neutral",
    label: "Neutral",
  },
  {
    value: "Hostile",
    label: "Hostile",
  },
  {
    value: "Uninformed",
    label: "Uninformed",
  },
  {
    value: "Expert",
    label: "Expert",
  },
  {
    value: "Business",
    label: "Business",
  },
]

function OutputGenerator({
  jobIdentifier,
  intialState,
  title,
  fields,
  type,
  auth,
  generated
}) {
  const [form, setForm] = useState(intialState)
  const [polling, setPolling] = useState(false)
  const limitPoll = useRef(0)
  const state = useContext(EditorContext)
  const dispatch = useDispatch()
  console.log(state, "harshs")
  const [lg , setlg] = useState(window.innerWidth > 1200)
  const generate = useRef(null);

  const [jobId, setJobId] = useState("")
  const [generatedEmail, setGeneratedEmail] = useState("")
  const [numberOfVariations, setNumberOfVariations] = useState(2)

  const [language, setLanguage] = useState("English")
  const [selectedTone, setSelectedTone] = useState("Formal")
  const [wordCount, setWordCount] = useState("")

  const [currJob, setCurrJob] = useState({})

  const pollTime = jobIdentifier == "longForm" ? 10000 : 3000

  const config = {
    key: "2134545",
    type: "loading",
    content: "Job in progress",
    duration: 0,
    className: "custom-ant-message-container",
  }

  // console.log(type, 'dsdsdd')

  const checkFormisFilled = () => {
    if (fields.length == 1) {
      return form[[fields[0].formName]] == ""
    } else {
      return (
        form[[fields[1].formName]] == "" || form[[fields[0].formName]] == ""
      )
    }
  }

  console.log(polling, "outer")

  const getPlainStringOfOutput = (outputArray) => {
    let finalString = ""
    let outputs = outputArray.map((variation, index) => {
      // since api payload structure changed from [string] -> [Objects]
      // so passing simple string down the line extracting from output key of object

      if (typeof variation === "object") {
        // variation_data = variation
        variation = variation.output
      }

      let str =
        `Variation- ${index + 1} ` +
        "\n" +
        variation.substr(1) +
        "\n" +
        linebreak +
        "\n"

      finalString += str
      return str
    })

    state.setEditorState(
      EditorState.createWithContent(ContentState.createFromText(finalString))
    )
    // if (
    //   auth?.user?.appSumoReview?.showAppSumoReviewPopUp &&
    //   outputs.length
    // ) {
    //   setTimeout(() => {
    //     setShowAppSumo(true);
    //   }, 4000);
    // }

    return outputs
  }

  useEffect(() => {
    if (polling) {
      const intervalId = setInterval(() => {
        if (limitPoll.current >= 2 * numberOfVariations + 4) {
          setPolling(false)
          clearInterval(intervalId)
          setForm(intialState)
          limitPoll.current = 0
          message.destroy("2134545")

          message.info(
            "It is taking longer than usual. Please check the job history tab for status"
          )
        }
        limitPoll.current = limitPoll.current + 1

        fetchDataAndProceed(
          {
            url: `${process.env.REACT_APP_BASE_URL}/jobs/${jobId}`,
          },
          (err, res) => {
            if (!err) {
              if (res?.data?.data[0]?.status == "started") {
              } else {
                setCurrJob(res.data.data[0])

                setGeneratedEmail(
                  getPlainStringOfOutput(res.data.data[0].output)
                )
                setWordCount(res.data.data[0]?.wordCount)

                setPolling(false)

                clearInterval(intervalId)
                limitPoll.current = 0
                dispatch(fetchUserProfile())
                message.destroy("2134545")
              }
            }
          }
        )
      }, pollTime)
    }
  }, [polling])

  const generateEmail = () => {
    if (numberOfVariations == "" || typeof numberOfVariations === "string") {
      setNumberOfVariations(1)
    }
    if (jobIdentifier == "longForm" && form[[fields[1].formName]].length < 3) {
      return message.error("Please provide atleast 3 key words")
    }
    if (jobIdentifier == "shortForm" && form[[fields[1].formName]].length > 2) {
      return message.error("Atmost 2 key words allowed for this job")
    }
    const payload = {
      ...form,
      jobIdentifier,
      numberOfVariations: numberOfVariations,
      language,
      tone: selectedTone,
    }
    console.log(payload)

    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/jobs/autoryt`,
        data: payload,
        method: "POST",
        loader: false,
      },
      (err, res) => {
        if (!err && res) {
          message.success(res?.data?.message)
          setPolling(true)
          setJobId(res.data.data.jobId)
          dispatch(
            updatePageState({
              generated: {
                ...generated,
                output: res.data.data,
                LikeDislikeOptions: { liked: false , disliked: false }
              },
            })
          )
          message.open(config)
          if (jobIdentifier == "longForm") {
            notification.info({
              duration: 15,
              description:
                "This job type ususally takes a minute to complete.You can wait here or check Job history to check results later.",
            })
          }
        } else {
          message.error(err?.response?.data.message)
        }
      }
    )
    if (generate.current) {
      !lg && generate.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  return (
    <>
      {/* {setSelectedMedia && (
        <Button
          type="primary"
          onClick={() => setSelectedMedia({})}
          style={{ marginLeft: "70px" }}
        >
          {" "}
          {"<-"} All Options{" "}
        </Button>
      )} */}
      <div className="component2 ori-flex ori-flex-jsb">
        <div className="c2-left ori-flex  ori-flex-ctr">
          <div
            style={{
              width: "100%",
              overflowY: "auto",
              // padding: '0px 20px'
            }}
          >
            {/* {fields.length > 1 && (
             
            )} */}
            {fields.length > 1 &&
              jobIdentifier !== "longForm" &&
              jobIdentifier !== "shortForm" && (
                <div className="ori-mrgn-tb-20 ">
                  <label className="ori-bold ">{fields[1].title}</label>
                  <Input
                    style={{ height: "40px" }}
                    placeholder={fields[1].placeholder}
                    value={form[[fields[1].formName]]}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [fields[1].formName]: e.target.value,
                      })
                    }
                  />
                </div>
              )}

            <Form.Item
              className="ori-mrgn-tb-20 "
              validateStatus={
                type == "socialmedia" &&
                form[[fields[0].formName]]?.length >= 100 &&
                "error"
              }
              help={
                type == "socialmedia" &&
                form[[fields[0].formName]]?.length >= 100 &&
                "You have exceeded the maximum number of 100 characters in this field"
              }
            >
              <label className="ori-bold ">{fields[0].title}</label>
              <Input.TextArea
                minLength={40}
                maxLength={type == "socialmedia" ? 100 : 1000}
                rows={
                  jobIdentifier == "longForm" ||
                  type == "socialmedia" ||
                  jobIdentifier == "shortForm"
                    ? 3
                    : 10
                }
                style={{ resize: "none" }}
                placeholder={fields[0].placeholder}
                value={form[[fields[0].formName]]}
                onChange={(e) =>
                  setForm({ ...form, [fields[0].formName]: e.target.value })
                }
              />
            </Form.Item>

            {(jobIdentifier == "longForm" || jobIdentifier == "shortForm") && (
              <>
                <div className="ori-mrgn-tb-20 ">
                  <label className="ori-bold ">{fields[1].title}</label>
                  <Select
                    mode="tags"
                    placeholder="Please type keywords"
                    defaultValue={[]}
                    value={form[[fields[1].formName]]}
                    onChange={(val) => {
                      console.log(val)
                      setForm({
                        ...form,
                        [fields[1].formName]: val,
                      })
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="ori-mrgn-tb-20 ">
                  <label className="ori-bold ">Audience</label>
                  <Select
                    style={{ width: "100%" }}
                    options={audienceOptions}
                    placeholder="Please select audience type"
                    defaultValue={"Neutral"}
                  />
                </div>
              </>
            )}
            {jobIdentifier !== "longForm" && jobIdentifier !== "shortForm" && (
              <div className="ori-mrgn-tb-20 ">
                <label className="ori-bold ">
                  Number of Variations (Try 2 variations everytime for best
                  results)
                </label>
                <Input
                  type="number"
                  value={numberOfVariations}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setNumberOfVariations(e.target.value)
                      return
                    }
                    const val = parseInt(e.target.value)

                    if (!val) return
                    if (val <= 0 || val > 5) return

                    setNumberOfVariations(e.target.value)
                  }}
                />
              </div>
            )}

            <div className="ori-mrgn-tb-20 ">
              <label className="ori-bold ">Tone</label>
              <Select
                style={{ width: "100%" }}
                options={toneOptions}
                placeholder="Please select a tone"
                defaultValue={"Formal"}
                value={selectedTone}
                onChange={(value) => setSelectedTone(value)}
              />
            </div>

            <div className="ori-mrgn-tb-20 ">
              <label className="ori-bold ">Select Language</label>

              <br />

              <Select
                onChange={(value) => {
                  console.log("selected language value = ", value)
                  setLanguage(value)
                }}
                style={{ width: "100%" }}
                defaultValue={language}
                options={languages_for_content_generator}
              />
            </div>

            <Button
              style={{ width: "100%", height: "40px", borderRadius: "5px" }}
              type="primary"
              onClick={generateEmail}
              disabled={polling || checkFormisFilled()}
              ref={generate}
            >
              Generate
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

function mapstatetoprops(state) {
  return {
    auth: state.auth,
    generated: state.page_details.generated
  };
}

export default connect(mapstatetoprops)(OutputGenerator);

// <div className="c2-right ori-flex ori-flex-ac ori-flex-ctr">
//   {polling ? (
//     <div
//       className="ori-bg-white "
//       style={{
//         height: "500px",
//         width: "100%",
//         border: "1px solid #d9d9d9",
//         padding: "10px"
//       }}
//     >
//       {" "}
//       <Skeleton active paragraph={{ rows: 10 }} />
//     </div>
//   ) : (
//     <div
//       className="ori-bg-white "
//       style={{
//         height: "500px",
//         width: "100%",
//         border: "1px solid #d9d9d9",
//         padding: "10px",
//         overflowY: "auto"
//       }}
//     >
//       <p
//         style={{
//           color: "#6D6D6D",
//           fontSize: "16px",
//           fontWeight: "500",
//           opacity: "0.5"
//         }}
//       >
//         Output{" "}
//       </p>

//       {generatedEmail &&
//         generatedEmail.map((item, index) => {
//           return (
//             <>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between"
//                 }}
//               >
//                 <span>
//                   Variation- {index + 1}{" "}
//                   <span style={{ fontSize: "12px" }}>
//                     {`(${item?.split(" ")?.length} words, ${
//                       item?.length
//                     } characters)`}
//                   </span>
//                 </span>

//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <LikeDislike
//                     item={currJob}
//                     showLikeDisLikeButtons={
//                       typeof currJob?.output === "object" ? true : false
//                     }
//                     variation_data={currJob.output[index]}
//                     currJobHistory={[currJob]}
//                     newJobHistoy={(newGeneratedEmail) => {
//                       setGeneratedEmail(
//                         getPlainStringOfOutput(newGeneratedEmail[0].output)
//                       )
//                       setCurrJob(newGeneratedEmail[0])
//                     }}
//                   />

//                   {/* <CopyToClipboard
//                           text={item}
//                           // style={{ position: 'absolute', right: '0' }}
//                         >
//                         </CopyToClipboard> */}

//                   <Button
//                     onClick={() => {
//                       navigator.clipboard.writeText(item)
//                       message.info("Text Copied!")
//                     }}
//                     icon={<CopyOutlined />}
//                     type="text"
//                   />
//                 </div>
//               </div>

//               <pre style={{ whiteSpace: "pre-wrap" }}>{item}</pre>
//               <div>{linebreak} </div>
//             </>
//           )
//         })}
//     </div>
//   )}
// </div>
