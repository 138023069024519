import { USER_LOGGED_IN } from "./actionTypes"
import states from "./state"

export default function user(state = states.auth_details, action) {
  switch (action.type) {
    case USER_LOGGED_IN: {
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
        loading: false,
        error: null,
      }
    }

    default:
      return { ...state }
  }
}
