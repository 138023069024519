import { Button, Form, Input, message, Select, Tooltip } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { fetchUserProfile } from "../../../data/redux/auth/action"
import { languages_for_content_generator } from "../../../data/utils/common"
import {
  fetchDataAndProceed,
  fetchUrlParams,
} from "../../../data/utils/datautility"
const toneOptions = [
  {
    value: "Formal",
    label: "Formal",
  },
  {
    value: "Informal",
    label: "Informal",
  },
  {
    value: "Humorous",
    label: "Humorous",
  },
  {
    value: "Serious",
    label: "Serious",
  },
  {
    value: "Optimistic",
    label: "Optimistic",
  },
  {
    value: "Motivating",
    label: "Motivating",
  },
  {
    value: "Respectful",
    label: "Respectful",
  },
  {
    value: "Assertive",
    label: "Assertive",
  },
  {
    value: "Conversational",
    label: "Conversational",
  },
]
const audienceOptions = [
  {
    value: "Neutral",
    label: "Neutral",
  },
  {
    value: "Hostile",
    label: "Hostile",
  },
  {
    value: "Uninformed",
    label: "Uninformed",
  },
  {
    value: "Expert",
    label: "Expert",
  },
  {
    value: "Business",
    label: "Business",
  },
]

const config = {
  key: "2134545",
  type: "loading",
  content: "Job in progress",
  duration: 0,
  className: "custom-ant-message-container",
}

function ArticleForm({
  setHeadlineList,
  queryParams,
  selectedHeadline,
  setOutlineList,
  headlineList,
  outlineList,
  setArticle,
  selectedOutline,
  jobId,
  setJobId,
  individual,
  article,
}) {
  const [language, setLanguage] = useState("English")
  const [topic, setTopic] = useState("")
  const [keywords, setKeyWords] = useState([])
  const [selectedTone, setSelectedTone] = useState("Formal")
  const [polling, setPolling] = useState(false)

  const [numberOfVariations, setNumberOfVariations] = useState(2)
  const params = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const pollTime = 15000
  const limitPoll = useRef(0)
  const [pollFor, setPollFor] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const textForGenerateButton = () => {
    if (params.step == "0") {
      if (headlineList?.length) return "Regenerate headline"
      else return "Generate headline"
    } else if (params.step == "1") {
      console.log(outlineList, "as")
      if (outlineList?.length) return "Regenerate outline"
      else return "Generate outline"
    } else {
      if (article) return "Regenerate article"
      else return "Get article"
    }
  }

  useEffect(() => {
    const query = fetchUrlParams(location.search?.substring(1))
    console.log(query, "harsh")
    if (query?.jobId) {
      setJobId(query?.jobId)

      if (!selectedHeadline) {
        navigate(`/tool/blog/longForm/0?jobId=${query?.jobId}`)
      }
      if (!selectedOutline) {
        navigate(`/tool/blog/longForm/1?jobId=${query?.jobId}`)
      }
      fetchDataAndProceed(
        {
          url: `${process.env.REACT_APP_BASE_URL}/jobs/${query?.jobId}`,
        },
        (err, res) => {
          //  console.log(!res?.data?.data[0]?.metaData[pollFor]);
          if (!err) {
            setTopic(res?.data?.data[0].metaData?.topic)
            setKeyWords(res?.data?.data[0].metaData?.keywords)
            setSelectedTone(res?.data?.data[0].metaData?.tone)
            setLanguage(res?.data?.data[0].metaData?.language)

            setHeadlineList(res?.data?.data[0].metaData?.headlines.split("\n"))
            setOutlineList(res?.data?.data[0].metaData?.outlines)
            setArticle(res?.data?.data[0].output[0]?.output)
          } else {
            navigate("/tool/blog/longForm/0")
          }
        }
      )
    } else {
      navigate("/tool/blog/longForm/0")
    }
  }, [])

  useEffect(() => {
    if (polling) {
      const intervalId = setInterval(() => {
        if (limitPoll.current >= 6) {
          setPolling(false)
          clearInterval(intervalId)

          limitPoll.current = 0
          message.destroy("2134545")

          message.info(
            "It is taking longer than usual. Please check the job history tab for status"
          )
        }
        limitPoll.current = limitPoll.current + 1

        fetchDataAndProceed(
          {
            url: `${process.env.REACT_APP_BASE_URL}/jobs/${jobId}`,
          },
          (err, res) => {
            //  console.log(!res?.data?.data[0]?.metaData[pollFor]);
            if (!err) {
              if (!res?.data?.data[0]?.metaData[pollFor]) {
              } else {
                console.log(res)

                setHeadlineList(
                  res?.data?.data[0].metaData?.headlines.split("\n")
                )
                setOutlineList(res?.data?.data[0].metaData?.outlines)
                setArticle(res?.data?.data[0].output[0]?.output)

                setPolling(false)

                clearInterval(intervalId)
                // console.log('helloooo2', intervalId);
                limitPoll.current = 0
                dispatch(fetchUserProfile())
                message.destroy("2134545")
              }
            }
          }
        )
      }, pollTime)
    }
  }, [polling])

  const highlightButton = () => {
    if (params.step == "0") {
      if (headlineList?.length) return false
      return true
    } else if (params.step == "1") {
      if (outlineList?.length) return false
      return true
    } else {
      if (article) return false
      return true
    }
  }
  // console.log(highlightButton(), "addas");
  const generateContent = () => {
    if (params.step == "0") {
      setPollFor("headlines")
      var payload = {
        description: topic,
        blogTopic: keywords,
        jobIdentifier: "longForm",
        language: language,
        tone: selectedTone,

        type: "headline",
      }
    } else if (params.step == "1") {
      setPollFor("outlines")
      var payload = {
        headline: selectedHeadline.text,
        jobIdentifier: "longForm",
        language: language,
        tone: selectedTone,
        type: "outline",
        numberOfVariations: numberOfVariations,
        jobId,
      }
    } else {
      setPollFor("article")
      let outlines = []
      selectedOutline?.map((item, index) => {
        item.text.split("\n")?.map((outline) => {
          if (outline)
            outlines.push(individual ? `${index + 1}. ` + outline : outline)
        })
      })
      var payload = {
        headline: selectedHeadline.text,
        jobIdentifier: "longForm",
        language: language,
        tone: selectedTone,
        type: "article",

        jobId,
        outlines,
      }
    }
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/jobs/autoryt`,
        method: "POST",
        data: payload,
      },
      (err, res) => {
        console.log(res)
        if (res.data) {
          setJobId(res.data?.data?.jobId)
          window.history.replaceState(
            null,
            null,
            `?jobId=${res.data?.data?.jobId}`
          )
          setPolling(true)
          limitPoll.current = 0
          message.open(config)
        }
      }
    )
  }

  return (
    <div
      className="ori-padding-20 ori-flex"
      style={{
        height: !isMobile ? "calc(100vh - 68px)" : "",
        overflowY: !isMobile ? "auto" : "hidden",
        flexDirection: "column",
      }}
    >
      <div style={{ overflowY: "auto", flexGrow: 1 }}>
        {selectedHeadline && (
          <div className="ori-mrgn-tb-20 ">
            <label className="ori-bold ">Headline</label>
            <div
              className="ori-bg-white "
              style={{
                flexGrow: 1,
                width: "100%",
                border: "1px solid #d9d9d9",
                padding: "10px",
              }}
            >
              {selectedHeadline?.text?.split(/^[- 0-9.]*/)[1]}
            </div>
          </div>
        )}

        {selectedOutline?.length ? (
          <div className="ori-mrgn-tb-20 ">
            <label className="ori-bold ">Outline</label>
            <div
              className="ori-bg-white "
              style={{
                flexGrow: 1,
                width: "100%",
                border: "1px solid #d9d9d9",
                padding: "10px",
              }}
            >
              {selectedOutline?.map((item, index) => {
                console.log(item)
                return (
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      margin: 0,
                      fontSize: "14px",
                      fontFamily: "inherit",
                    }}
                  >
                    {individual && `${index + 1}. `}
                    {item?.text}
                  </pre>
                )
              })}
            </div>
          </div>
        ) : (
          <></>
        )}

        <Form.Item className="ori-mrgn-tb-20 ">
          <label className="ori-bold ">Topic</label>
          <Input.TextArea
            rows={2}
            value={topic}
            disabled={parseInt(params?.step) > 0}
            onChange={(e) => setTopic(e.target.value)}
            style={{ resize: "none" }}
            placeholder={"Topic for the article"}
          />
        </Form.Item>

        <div className="ori-mrgn-tb-20 ">
          <label className="ori-bold ">Keywords</label>
          <Select
            mode="tags"
            placeholder="Please type keywords"
            defaultValue={[]}
            disabled={parseInt(params?.step) > 0}
            value={keywords}
            onChange={(val) => setKeyWords(val)}
            style={{ width: "100%" }}
          />
        </div>
        <div className="ori-mrgn-tb-20 ">
          <label className="ori-bold ">Audience</label>
          <Select
            style={{ width: "100%" }}
            disabled={parseInt(params?.step) > 0}
            options={audienceOptions}
            placeholder="Please select audience type"
            defaultValue={"Neutral"}
          />
        </div>

        <div className="ori-mrgn-tb-20 ">
          <label className="ori-bold ">Select tone</label>

          <br />

          <Select
            style={{ width: "100%" }}
            value={selectedTone}
            onChange={(value) => setSelectedTone(value)}
            disabled={parseInt(params?.step) > 0}
            options={toneOptions}
          />
        </div>
        <div className="ori-mrgn-tb-20 ">
          <label className="ori-bold ">Select Language</label>

          <br />

          <Select
            style={{ width: "100%" }}
            defaultValue={language}
            value={language}
            disabled={parseInt(params?.step) > 0}
            onChange={(value) => setLanguage(value)}
            options={languages_for_content_generator}
          />
        </div>
        {params.step == "1" && (
          <div className="ori-mrgn-tb-20 ">
            <label className="ori-bold ">
              Number of Variations (Try 2 variations everytime for best results)
            </label>
            <Input
              type="number"
              value={numberOfVariations}
              onChange={(e) => {
                if (e.target.value == "") {
                  setNumberOfVariations(e.target.value)
                  return
                }
                const val = parseInt(e.target.value)

                if (!val) return
                if (val <= 0 || val > 5) return

                setNumberOfVariations(e.target.value)
              }}
            />
          </div>
        )}
      </div>
      <Tooltip
        title={`Click here to ${textForGenerateButton()}`}
        open={highlightButton() && !polling}
      >
        <Button
          style={{
            width: "100%",
            height: "40px",
            borderRadius: "5px",
            border: highlightButton() ? "1px solid white" : "",
            boxShadow: highlightButton() ? "0px 0px 2px 2px #6610EA" : "",
          }}
          type="primary"
          onClick={generateContent}
          disabled={!topic || !keywords?.length || polling}
          loading={polling}
        >
          {textForGenerateButton()}
        </Button>
      </Tooltip>
    </div>
  )
}

export default ArticleForm
