import React, { useEffect, useState } from "react"
import { Button, Collapse, Tag, Modal, Input, message } from "antd"
import InfiniteScroll from "react-infinite-scroll-component"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import "./history.css"
import {
  CopyOutlined,
  LikeOutlined,
  DislikeOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import { CopyToClipboard } from "react-copy-to-clipboard"
import LikeDislike from "./LikeDislike"
import { useLocation } from "react-router-dom"

const JobType = {
  email: "Email Generation",
  facebook: "Facbook Ad",
  insta: "Instagram Ad",
  tweet: "Tweet Generator",
  linkedin: "LinkedIn Post",
  blogTitle: "Blog Title Generator",
  blogIdeas: "Blog Idea Generator",
  blogIntro: "Blog Intro Generator",
  blogHeadlineExpander: "Blog headline Expander",
  blogOutline: "Blog outline Generator",
  blogConclusion: "Blog Conclusion",
  seoMeta: "Meta description",
  headline: "Headline Generator",
  valuePropostion: "Value Proposition",
  productDescription: "Product description",
  poetry: "Poetry",
  transcribe: "Audio/video Transcription",
  onboardApp: "Onboarding Messages",
  titleApp: "Website/App Title",
  descriptionApp: "Website/App Description",
  marketingEmail: "Marketing Email",
  longForm: "Long Form Articles",
  shortForm: "Short Form Articles",
  ytidea: "Youtube Video Idea",
  ytscriptoutline: "Youtube Video Script Outline",
  ythookintro: "Youtube Video Hook Intro",
}
let linebreak =
  "*************************************************************************************************"

function History() {
  const [jobHistory, setJobHistory] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [openCollapse, setOpenCollapse] = useState([])

  const location = useLocation()

  console.log(openCollapse, "harsh", location.state)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/jobs/list?limit=10&page=${page}`,
      },
      (err, res) => {
        if (!err) {
          if (res?.data?.data) {
            setJobHistory([...res.data.data])

            if (location.state.__id) {
              res.data.data.forEach((item, index) => {
                if (item.output[0]._id == location.state?.__id)
                  setOpenCollapse([index + ""])
              })
            }
            if (res.data.data.length < 10) setHasMore(false)
          }
        }
      }
    )
  }, [])

  const fetchMoreJobs = () => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/jobs/list?limit=10&page=${
          page + 1
        }`,
      },
      (err, res) => {
        console.log(res)
        if (!err) {
          if (res?.data?.data) {
            setJobHistory([...jobHistory, ...res.data.data])
          }
          setPage(page + 1)
          if (res.data.data.length < 10) setHasMore(false)
        }
      }
    )
  }

  const deleteJob = (job = {}) => {
    try {
      if (!job || !job.jobId) {
        alert("Unknown Job")
        return
      }

      fetchDataAndProceed(
        {
          url: `${process.env.REACT_APP_BASE_URL}/jobs/${job.jobId}`,
          method: "DELETE",
        },
        (err, res) => {
          if (err) throw new Error("Error while deleting the object.")

          setJobHistory((prevData) => {
            let jobHistoryDup = prevData.filter(
              (job) => job.jobId !== res.data.data.jobId
            )
            return jobHistoryDup
          })
        }
      )
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div
      style={{
        padding: !isMobile ? "0px 60px" : "0px 30px",
        marginBottom: "20px",
      }}
    >
      <div
        className="ori-flex ori-flex-ctr ori-flex-ac"
        style={{ flexDirection: "column", marginTop: "30px" }}
      >
        <span style={{ fontSize: "30px", fontWeight: "600" }}>Job History</span>
        <span style={{ fontSize: "16px", fontWeight: "500", color: "#6D6D6D" }}>
          Go through your previous orders and check your quota{" "}
        </span>
      </div>
      {jobHistory.length == 0 ? (
        <div
          className="ori-flex ori-flex-ac"
          style={{ flexDirection: "column" }}
        >
          <h1 style={{ marginTop: "50px", color: "#7000f4" }}>
            No Jobs performed
          </h1>
          <h3>Waiting for you to create your first Job</h3>
        </div>
      ) : (
        <>
          <div
            className="ori-flex ori-flex-jsb ori-flex-ac"
            style={{
              padding: "10px 0px",
              background: "#5816AE",
              height: "58px",
              color: "white",
              marginTop: "30px",
            }}
          >
            <div style={{ marginLeft: "45px", fontSize: "14px" }}>Job Type</div>
            <div>
              <span style={{ marginRight: "40px", fontSize: "14px" }}>
                Quota Exhausted
              </span>
              <span style={{ marginRight: "40px", fontSize: "14px" }}>
                Job Status
              </span>
              <span style={{ marginRight: "40px", fontSize: "14px" }}>
                Action
              </span>
            </div>
          </div>
          <div id="scrollableDiv" className="jobhistory">
            <InfiniteScroll
              dataLength={jobHistory.length}
              next={fetchMoreJobs}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
            >
              <Collapse defaultActiveKey={openCollapse}>
                {jobHistory &&
                  jobHistory.map((item, index) => {
                    // console.log("item = ", item);

                    if (item.isDeleted) return null

                    return (
                      <Collapse.Panel
                        key={index + ""}
                        header={JobType[item?.jobIdentifier]}
                        style={{ padding: "10px 5px" }}
                        className="history-panel-background"
                        extra={
                          <>
                            <span style={{ marginRight: "40px" }}>
                              {item?.jobIdentifier == "transcribe"
                                ? item?.duration
                                : item?.wordCount}
                            </span>
                            <Tag style={{ marginRight: "40px" }}>
                              {item?.status}
                            </Tag>
                            <DeleteOutlined
                              onClick={() => deleteJob(item)}
                              style={{
                                marginleft: "30px",
                                marginRight: "10px",
                              }}
                            />
                          </>
                        }
                      >
                        <p>
                          {item?.jobIdentifier == "transcribe" ? (
                            <></>
                          ) : (
                            <>
                              <b>Word Count</b>: {item?.wordCount}
                            </>
                          )}
                        </p>
                        <h3>Input</h3>
                        <p
                          className="ori-bg-light  ori-padding-10"
                          style={{
                            borderRadius: "5px",
                            border: "1px solid #d9d9d9",
                          }}
                        >
                          {item?.job?.text}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <h3>Output</h3>

                          <div
                            style={{
                              position: "absolute",
                              right: isMobile ? "55px" : "25px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LikeDislike
                              item={item}
                              showLikeDisLikeButtons={true}
                              currJobHistory={jobHistory}
                              newJobHistoy={(history) => {
                                setJobHistory((prevData) => {
                                  return history
                                })
                              }}
                            />
                          </div>
                        </div>
                        {item?.output?.map((variation, index) => {
                          if (typeof variation === "object") {
                            variation = variation.output
                          }

                          let str =
                            "\n" +
                            "Variation - " +
                            (index + 1) +
                            "\n" +
                            variation.substr(1) +
                            "\n" +
                            linebreak +
                            "\n" +
                            `(${variation?.split(" ")?.length} words, ${
                              variation?.length
                            } characters)`

                          return (
                            <pre
                              className="ori-bg-light  ori-padding-10"
                              style={{
                                borderRadius: "5px",
                                border: "1px solid #d9d9d9",
                                whiteSpace: "pre-wrap",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  right: "0",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CopyToClipboard
                                  text={variation}
                                  // style={{ position: 'absolute', right: '0' }}
                                >
                                  <Button
                                    onClick={() => message.info("Text Copied!")}
                                    icon={<CopyOutlined />}
                                    type="text"
                                  />
                                </CopyToClipboard>
                              </div>

                              {str}
                            </pre>
                          )
                        })}
                      </Collapse.Panel>
                    )
                  })}
              </Collapse>
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  )
}

export default History
