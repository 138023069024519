import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import { AllTopics } from "../../data/config/constant"
import { useParams } from "react-router-dom"
import CardContainer from "../CardContainer"
import EditorComponent from "../EditorComponent"
import OutputGenerator from "../OutputGenerator"
import Topbar from "../Topbar"
import { updateSelectedTiledata } from "../../data/redux/routestate/action"

const socialMediaHandles = AllTopics.filter(
  (item) => item.type === "socialmedia"
).flatMap((topic) => {
  if (!topic.childs) return [topic]
  else return topic.childs
})
const othersocialMediaHandles = AllTopics.filter(
  (item) => item.type === "socialmedia"
).flatMap((topic) => {
  if (!topic.childsother) return [topic]
  else return topic.childsother
})
function SocialMedia({ selectedTile, dispatch }) {
  const params = useParams()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)
  const [lg , setlg] = useState(window.innerWidth > 1200)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
      setlg(window.innerWidth >= 1200)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  useEffect(() => {
    const selectedTile = socialMediaHandles.find((item) => {
      return item.route === params.type
    })

    const payload = { ...selectedTile }
    payload.icon = ""

    dispatch(
      updateSelectedTiledata({
        selectedTile: payload,
      })
    )
  }, [])

  return (
    <div className="ori-flex ori-flex-wrap">
       <div
        style={{
          width: selectedTile.title && lg ? "45%" : "100%",
        }}
      >
        <Topbar
          text={selectedTile.title ? selectedTile.title : "Social Media"}
        />
        {selectedTile.title ? (
          <OutputGenerator {...selectedTile} />
        ) : (
          <div
            style={{
              padding: "0px 10px",
              height: "calc(100vh - 100px)",
              overflowY: "auto",
              marginTop: "10px",
              marginBottom: isMobile ? "20px" : "",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                marginLeft: isMobile ? "30px" : "22px",
                paddingTop: "15px",
                fontWeight: "bold"
              }}
            >
              Youtube
            </span>
            <div
              className="ori-flex"
              style={{
                flexWrap: "wrap",
                display: isMobile ? "flex" : "",
                justifyContent: isMobile ? "center" : "",
                paddingBottom: "20px"
              }}
            >
              {socialMediaHandles.map((item) => {
                return (
                  <CardContainer
                    item={item}
                    width="31%"
                    marginLeft={!isMobile ? "20px" : "0px"}
                  />
                )
              })}
              </div>
              <span
                style={{
                  fontSize: "16px",
                  marginLeft: isMobile ? "30px" : "20px",
                  fontWeight: "bold"
                }}
              >
                Other Social Media Tools
              </span>
              <div
                className="ori-flex"
                style={{
                  overflowY: "auto",
                  flexWrap: "wrap",
                  display: isMobile ? "flex" : "",
                  justifyContent: isMobile ? "center" : "",
                }}
              >
                {othersocialMediaHandles.map((item) => {
                  return (
                    <CardContainer
                      item={item}
                      width="31%"
                      marginLeft={!isMobile ? "20px" : "0px"}
                    />
                  )
                })}
              </div>
          </div>
        )}
      </div>
      {selectedTile.title && (
        <div
          style={{ width: lg ? "55%" : "100%", height: "100vh" }}
          className={`ori-bg-white ${
            !isMobile ? "ori-padding-20" : "ori-padding-10"
          }`}
        >
          <EditorComponent />
        </div>
      )}
    </div>
  )
}
const mapstatetoprops = (state) => {
  return {
    selectedTile: state.routestate.selectedTile,
  }
}

export default connect(mapstatetoprops)(SocialMedia)
