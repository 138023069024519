import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
} from "@ant-design/icons"
import { AiOutlineMail } from "react-icons/ai"

import { CgNotes } from "react-icons/cg"
import { CgTranscript } from "react-icons/cg"

export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
export const AllTopics = [
  {
    path: "blog/longForm/0",
    type: "content",
    route: "longFormArticle",
    title: "SEO-Friendly Article Generator - Long Form",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "", blogTopic: [] },
    jobIdentifier: "longForm",
    description: "1 Click Long blogs - Unlocking the Power of Creativity",
    fields: [
      { title: "Title", formName: "description", placeholder: "" },
      { title: "Keywords", formName: "blogTopic", placeholder: "" },
    ],
  },
  {
    path: "blog",
    type: "content",
    title: "SEO-Friendly Blog Generator - Short Form",
    route: "shortFormBlog",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "", blogTopic: [] },
    jobIdentifier: "shortForm",
    description: "1 Click Short blogs - Unlocking the Power of Creativity",
    fields: [
      { title: "Title", formName: "description", placeholder: "" },
      { title: "Keywords", formName: "blogTopic", placeholder: "" },
    ],
  },
  {
    path: "socialplatform",
    type: "socialmedia",
    title: "Youtube Content Generator",
    icon: <YoutubeOutlined style={{ color: "red" }} />,
    intialState: { description: "", blogTopic: "" },
    jobIdentifier: "yt",
    description: "Get Dynamic Youtube Content for Amazing Youtube Videos!",
    fields: [
      // { title: 'Description', formName: 'description', placeholder: '' },
      // { title: 'Product name', formName: 'blogTopic', placeholder: '' }
    ],
    childs: [
      {
        path: "socialplatform",
        type: "socialmedia",
        title: "Youtube Video Idea Generator",
        route: "ytVideoIdea",
        icon: <YoutubeOutlined style={{ fontSize: "36px", color: "red" }} />,
        intialState: { description: "" },
        jobIdentifier: "ytidea",
        description: "Create more Dynamic and Creative Videos!",
        fields: [
          {
            title: "Description",
            formName: "description",
            placeholder:
              "Add brief description to generate Youtube Video idea...",
          },
        ],
      },
      {
        path: "socialplatform",
        type: "socialmedia",
        title: "Youtube Video Script Outline Generator",
        route: "ytVideoScriptOutline",
        icon: <YoutubeOutlined style={{ fontSize: "36px", color: "red" }} />,
        intialState: { description: "" },
        jobIdentifier: "ytscriptoutline",
        description: "Create more Dynamic and Creative Videos!",
        fields: [
          {
            title: "Description",
            formName: "description",
            placeholder:
              "Add brief description to generate Youtube Video Script Outline...",
          },
        ],
      },
      {
        path: "socialplatform",
        type: "socialmedia",
        title: "Youtube Video Hook and Intro Generator",
        route: "ytVideoHook&Intro",
        icon: <YoutubeOutlined style={{ fontSize: "36px", color: "red" }} />,
        intialState: { description: "" },
        jobIdentifier: "ythookintro",
        description: "Create more Dynamic and Creative Videos!",
        fields: [
          {
            title: "Description",
            formName: "description",
            placeholder:
              "Add brief description to generate Youtube Video Hook and Intro...",
          },
        ],
      },
    ],
    childsother: [
      {
        path: "socialplatform",
        type: "socialmedia",
        title: "Instagram Ad and Caption Generator",
        route: "instaAd&Caption",
        icon: (
          <InstagramOutlined style={{ fontSize: "36px", color: "#9C3691" }} />
        ),
        intialState: { description: "", blogTopic: "" },
        jobIdentifier: "insta",
        description: "Get More Instagram Followers with Amazing Ads!",
        fields: [
          { title: "Description", formName: "description", placeholder: "" },
          { title: "Product name", formName: "blogTopic", placeholder: "" },
        ],
      },
      {
        path: "socialplatform",
        type: "socialmedia",
        title: "Facebook Ad and Caption Generator",
        route: "facebookAd&Caption",
        icon: (
          <FacebookOutlined style={{ fontSize: "36px", color: "#4167B1" }} />
        ),
        intialState: { description: "", blogTopic: "" },
        jobIdentifier: "facebook",
        description: "Facebook Ads That Convert: How to Write Amazing Copy",
        fields: [
          { title: "Description", formName: "description", placeholder: "" },
          { title: "Product name", formName: "blogTopic", placeholder: "" },
        ],
      },
      {
        path: "socialplatform",
        type: "socialmedia",
        title: "Twitter Content Generator",
        route: "twitterContent",
        icon: (
          <TwitterOutlined style={{ fontSize: "36px", color: "#229FED" }} />
        ),
        intialState: { description: "", keyword: "" },
        jobIdentifier: "tweet",
        description: "Write amazing tweets, that engage your amazing followers",
        fields: [
          { title: "Description", formName: "description", placeholder: "" },
          { title: "Keywords", formName: "keyword", placeholder: "" },
        ],
      },

      {
        path: "socialplatform",
        type: "socialmedia",
        title: "Linkedin Ad and Caption Generator",
        route: "linkedinAd&Caption",
        icon: (
          <LinkedinOutlined style={{ fontSize: "36px", color: "#0A66C2" }} />
        ),
        intialState: { description: "", blogTopic: "" },
        jobIdentifier: "linkedin",
        description: "How to Write an Effective Business Ad on LinkedIn",
        fields: [
          { title: "Description", formName: "description", placeholder: "" },
          { title: "Product name", formName: "blogTopic", placeholder: "" },
        ],
      },
    ],
  },

  {
    title: "Marketing Email Generator",
    path: "emailGenerator",
    route: "marketingEmail",
    type: "email",
    icon: <AiOutlineMail style={{ fontSize: "36px", color: "#F04B4B" }} />,

    intialState: { emailBody: "", emailTo: "" },
    description: "Get More Opens and Clicks",

    jobIdentifier: "marketingEmail",
    fields: [
      {
        title: "Description",
        formName: "emailBody",
        placeholder: "Describe the context of the email",
      },
      { title: "To", formName: "emailTo", placeholder: "Write a name..." },
    ],
  },
  {
    title: "Email Generator",
    path: "emailGenerator",
    type: "email",
    route: "email",
    icon: <AiOutlineMail style={{ fontSize: "36px", color: "#F04B4B" }} />,

    intialState: { emailBody: "", emailTo: "" },
    description: "Generate a great email ",

    jobIdentifier: "email",
    fields: [
      {
        title: "Description",
        formName: "emailBody",
        placeholder: "Describe the context of the email",
      },
      { title: "To", formName: "emailTo", placeholder: "Write a name..." },
    ],
  },

  {
    path: "seo",
    type: "seo",
    title: "SEO Meta Description AI Generator",
    route: "seoMetaDescription",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "" },
    jobIdentifier: "seoMeta",
    description: "Excerpt for your web page so that you get maximum visibility",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "seo",
    type: "seo",
    title: "Headline Generator",
    route: "headline",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "" },
    jobIdentifier: "headline",
    description: "Generate an amazing headline for your insighful content",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "seo",
    type: "seo",
    title: "Value Proposition AI Generator",
    route: "valuePropostion",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "" },
    jobIdentifier: "valuePropostion",
    description: "To help you answer your potential customers - 'Why us' ",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "seo",
    type: "seo",
    title: "Product Description AI Generator",
    route: "productDescription",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "", blogTopic: "" },
    jobIdentifier: "productDescription",
    description: "Generate an insightful description for your quality product",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
      { title: "Product name", formName: "blogTopic", placeholder: "" },
    ],
  },
  {
    path: "blog",
    type: "content",
    title: "Blog Headline and Title Generator",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "" },
    jobIdentifier: "blogTitle",
    route: "blogHeadline&Title",
    description: "Give great titles to your blogs",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "blog",
    type: "content",
    title: "Blog Headline Expander",
    route: "blogHeadlineExpander",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "", blogTopic: "" },
    jobIdentifier: "blogHeadlineExpander",
    description: "Generate blogs from headline",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
      { title: "Topic", formName: "blogTopic", placeholder: "" },
    ],
  },
  {
    path: "blog",
    type: "content",
    title: "Blog Intro Generator",
    route: "blogIntro",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    intialState: { description: "" },
    jobIdentifier: "blogIntro",
    description: "Introduce your informal topic",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },

  {
    path: "blog",
    type: "content",
    title: "Blog Idea Generator",
    route: "blogIdea",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,

    intialState: { description: "" },
    jobIdentifier: "blogIdeas",
    description: "Generate great ideas for your brand",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "blog",
    type: "content",
    title: "Blog Outline Generator",
    route: "blogOutline",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,

    intialState: { description: "" },
    jobIdentifier: "blogOutline",
    description: "To expand upon your fantastic blog topic",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "blog",
    type: "content",
    title: "Blog Conclusion Generator",
    route: "blogConclusion",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,

    intialState: { description: "" },
    jobIdentifier: "blogConclusion",
    description: "Words so that your audience keep on waiting for more",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "blog",
    type: "content",
    title: "Poetry Writing and Prompts AI Generator",
    route: "poetryWriting&Prompts",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,

    intialState: { description: "" },
    jobIdentifier: "poetry",
    description: "Write a mesmerizing poem",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "appweb",
    type: "application",
    title: "Onboarding Screen Messages",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    route: "onboardScreenMsgs",
    intialState: { description: "" },
    jobIdentifier: "onboardApp",
    description: "Get your onboarding right",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "appweb",
    type: "application",
    title: "Website/App Title Generation",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    route: "webAppTitle",
    intialState: { description: "" },
    jobIdentifier: "titleApp",
    description: "Generating the Perfect Title for Your Website or App",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    path: "appweb",
    type: "application",
    title: "Website/App Fillers and Descriptions",
    icon: <CgNotes style={{ fontSize: "36px", color: "#EBBD18" }} />,
    route: "webAppFillers",
    intialState: { description: "" },
    jobIdentifier: "descriptionApp",
    description: "The App and Website Filler's Guide to Catchy Descriptions",
    fields: [
      { title: "Description", formName: "description", placeholder: "" },
    ],
  },
  {
    title: "Audio/video Transcribe",
    type: "transcribe",
    icon: <CgTranscript style={{ fontSize: "36px" }} />,
    route: "",
    description: "Get transcription of your audio/video ",
    path: "transcribe",
  },
]

export const VIRAL_LOOP_DATA = {
  development:
    '!function(){var a=window.VL=window.VL||{};return a.instances=a.instances||{},a.invoked?void(window.console&&console.error&&console.error("VL snippet loaded twice.")):(a.invoked=!0,void(a.load=function(b,c,d){var e={};e.publicToken=b,e.config=c||{};var f=document.createElement("script");f.type="text/javascript",f.id="vrlps-js",f.defer=!0,f.src="https://app.viral-loops.com/client/vl/vl.min.js";var g=document.getElementsByTagName("script")[0];return g.parentNode.insertBefore(f,g),f.onload=function(){a.setup(e),a.instances[b]=e},e.identify=e.identify||function(a,b){e.afterLoad={identify:{userData:a,cb:b}}},e.pendingEvents=[],e.track=e.track||function(a,b){e.pendingEvents.push({event:a,cb:b})},e.pendingHooks=[],e.addHook=e.addHook||function(a,b){e.pendingHooks.push({name:a,cb:b})},e.$=e.$||function(a){e.pendingHooks.push({name:"ready",cb:a})},e}))}();var campaign=VL.load("dijdoiIvDAhiiGNaSBdEHrmlu9A",{autoLoadWidgets:!0});',
  production: "", // need to configure before going on production
}
