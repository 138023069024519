import { Avatar, Button, Divider, Dropdown, Menu } from "antd"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import logo from "../../data/assets/Logo.png"
import "./sider.css"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar"
import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch } from "react-redux"
import { updateSelectedTiledata } from "../../data/redux/routestate/action"
import ArrowIcon from "../../data/assets/picture-arrow.png"
import {
  AiFillGift,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineUsergroupAdd,
} from "react-icons/ai"

import { CleverTap } from "../../data/CleverTap"
import { Mixpanel } from "../../data/MixPanel"

function Sider({ siderModule, user }) {
  const [showTourMenu, setShowTourMenu] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const [collaped, setCollapsed] = useState(true)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)
  const [lg, setlg] = useState(window.innerWidth > 1200)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
      setlg(window.innerWidth >= 1200)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const getPercentage = (num, den) => {
    return Math.round((num / den) * 100)
  }

  const QuotaRender = () => {
    return (
      <div style={{ color: "white" }}>
        <div style={{ width: "100%" }}>
          <div style={{ fontWeight: "600" }} className="text-gradient">
            Words quota
          </div>
          <div
            className="ori-flex ori-flex-ac"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <div style={{ width: "30%", height: 50 }}>
              <CircularProgressbar
                value={getPercentage(
                  user?.quota_used,
                  user?.quota + +user.topup_quota
                )}
                text={
                  user?.quota == "Unlimited"
                    ? "∞"
                    : `${getPercentage(
                        user?.quota_used,
                        user?.quota + +user.topup_quota
                      )}%`
                }
                styles={buildStyles({
                  pathColor: "#7000f4",
                  textColor: "white",
                })}
              />
            </div>
            <div style={{ width: "70%", marginLeft: "10px", fontSize: "12px" }}>
              <span style={{ wordWrap: "break-word" }}>
                {" "}
                Words left -{" "}
                {user?.quota == "Unlimited" ? (
                  <span style={{ fontSize: "16px" }}>∞</span>
                ) : (
                  user?.quota + +user.topup_quota - user?.quota_used
                )}
              </span>
            </div>
          </div>
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div style={{ fontWeight: "600" }} className="text-gradient">
            Transcription quota
          </div>
          <div
            className="ori-flex ori-flex-ac"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <div style={{ width: "30%", height: 50 }}>
              <CircularProgressbar
                value={getPercentage(
                  user?.transcription_used,
                  user?.transcription_quota
                )}
                text={
                  user?.quota == "Unlimited"
                    ? "∞"
                    : `${getPercentage(
                        user?.transcription_used,
                        user?.transcription_quota
                      )}%`
                }
                styles={buildStyles({
                  pathColor: "#7000f4",
                  textColor: "white",
                })}
              />
            </div>
            <div style={{ width: "70%", marginLeft: "10px", fontSize: "12px" }}>
              <span style={{ wordWrap: "break-word" }}>
                Seconds left -{" "}
                {user?.quota == "Unlimited" ? (
                  <span style={{ fontSize: "16px" }}>∞</span>
                ) : (
                  user?.transcription_quota - user?.transcription_used
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const quotamenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div style={{ width: 230 }}>
              <div style={{ color: "#7000f4" }}>Words quota</div>
              <div className="ori-flex" style={{ marginTop: "10px" }}>
                <div style={{ width: 50, height: 50 }}>
                  <CircularProgressbar
                    value={getPercentage(user?.quota_used, user?.quota)}
                    text={`${getPercentage(user?.quota_used, user?.quota)}%`}
                    styles={buildStyles({
                      pathColor: "#7000f4",
                      textColor: "#7000f4",
                    })}
                  />
                </div>
                <div style={{ marginLeft: "10px", fontSize: "12px" }}>
                  <span> Words used - {user?.quota_used}</span>
                  <br></br>
                  <span> Words alloted - {user?.quota}</span>
                </div>
              </div>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div style={{ width: 230 }}>
              <div style={{ color: "#7000f4" }}>Transcription quota</div>
              <div className="ori-flex" style={{ marginTop: "10px" }}>
                <div style={{ width: 50, height: 50 }}>
                  <CircularProgressbar
                    value={getPercentage(
                      user?.transcription_used,
                      user?.transcription_quota
                    )}
                    text={`${getPercentage(
                      user?.transcription_used,
                      user?.transcription_quota
                    )}%`}
                    styles={buildStyles({
                      pathColor: "#7000f4",
                      textColor: "#7000f4",
                    })}
                  />
                </div>
                <div style={{ marginLeft: "10px", fontSize: "12px" }}>
                  <span> Seconds used - {user?.transcription_used}</span>
                  <br></br>
                  <span> Seconds alloted - {user?.transcription_quota}</span>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    />
  )
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Button
              style={{ marginRight: "20px", width: "100%", textAlign: "left" }}
              onClick={() => navigate("/tool/profile")}
              type="text"
            >
              My profile
            </Button>
          ),
        },
        // {
        //   key: "3",
        //   label: (
        //     <Button
        //       style={{ marginRight: "20px", width: "100%", textAlign: "left" }}
        //       onClick={() => navigate("/tool/socialaccounts")}
        //       type="text"
        //     >
        //       Social accounts
        //     </Button>
        //   )
        // },
        {
          key: "2",
          label: (
            <Button
              style={{ marginRight: "20px", width: "100%", textAlign: "left" }}
              onClick={() => {
                localStorage.clear()
                logout({ returnTo: "https://autorytr.com" })
              }}
              type="text"
            >
              Logout
            </Button>
          ),
        },
      ]}
    />
  )

  const rewardMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Button
              style={{ marginRight: "20px", width: "100%", textAlign: "left" }}
              onClick={() => navigate("/tool/redeem")}
              type="text"
            >
              Redeem code
            </Button>
          ),
        },
        {
          key: "2",
          label: (
            <Button
              style={{ marginRight: "20px", width: "100%", textAlign: "left" }}
              onClick={() => navigate("/tool/appreview")}
              type="text"
            >
              Earn Free Rewards
            </Button>
          ),
        },
        {
          key: "3",
          label: (
            <Button
              style={{ marginRight: "20px", width: "100%", textAlign: "left" }}
              onClick={() => navigate("/tool/referral")}
              type="text"
            >
              Referral
            </Button>
          ),
        },
      ]}
    />
  )

  const navigateToComponent = (item) => {
    console.log(item)

    navigate(item.path)
    dispatch(updateSelectedTiledata({ selectedTile: {} }))
  }
  const externalLink = () => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/stripe/create-customer-portal-session`,
        method: "POST",
        loader_text: "Redirecting. . .",
      },
      (err, res) => {
        if (!err) {
          console.log(res)
          window.location.href = res.data.data.url
        }
      }
    )
  }

  const hideTourMenu = () => {
    setShowTourMenu(false)
    localStorage.setItem("isGuidedTourAlreadyVisisted", true)
  }

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setShowTourMenu(false)
      localStorage.setItem("isGuidedTourAlreadyVisisted", true)
    }
  })

  useEffect(() => {
    const scroller = document.getElementById("scroller")

    setTimeout(() => {
      const isGuidedTourAlreadyVisisted =
        localStorage && localStorage.getItem("isGuidedTourAlreadyVisisted")
          ? localStorage.getItem("isGuidedTourAlreadyVisisted")
          : null

      if (!isGuidedTourAlreadyVisisted) {
        if (scroller) scroller.scrollTop = 1000
        // setShowTourMenu(true)
      }
    }, 200)
  }, [])

  const navigatePlan = (planType) => {
    navigateToComponent({ path: planType })
    if (planType === "pricing") {
      CleverTap.track("Upgrade Frontend",{
        type: planType,
        route: window.location.pathname
      })
      Mixpanel.identify(user.userId)
      Mixpanel.track("Upgrade")
    }
  }

  return (
    <div className="sider">
      {showTourMenu ? (
        <div className="guide-menu" onClick={hideTourMenu}>
          <div
            onClick={() => {
              navigate("redeem")
              hideTourMenu()
            }}
          ></div>

          <h1 onClick={hideTourMenu}> &times </h1>

          <div className="text-arrow-container">
            <div>
              {" "}
              <h1>You may redeem your coupon here...</h1>{" "}
            </div>
            <div>
              {" "}
              <img src={ArrowIcon} />{" "}
            </div>
          </div>
        </div>
      ) : null}

      <div
        className=" ori-bg-primary  ori-padding-10"
        style={{
          height: lg ? "100%" : "",
          boxShadow: "0 0 30px #e6e6e6",
        }}
      >
        <div className="ori-flex  ori-flex-ac" style={{}}>
          <img
            src={logo}
            style={{ width: "60px", height: "60px", cursor: "pointer" }}
            onClick={() => navigate("/tool")}
          ></img>
          <div style={{ color: "white", marginLeft: "10px", fontSize: "20px" }}>
            Autorytr
          </div>

          {/* <Dropdown overlay={quotamenu}>
            <span
              style={{
                marginTop: "20px",
                color: "#7000f4",
                color: "white",
                cursor: "pointer"
              }}
            >
              Quota Status
            </span>
          </Dropdown> */}
        </div>

        <div
          id="scroller"
          style={{
            marginTop: "20px",
            height: "calc(100vh - 480px)",
            overflowY: "auto",
          }}
        >
          {siderModule &&
            siderModule.map((item) => {
              if (item?.parent == "Library" && collaped) return
              return (
                <>
                  <div
                    className="ori-flex  ori-flex-ac sider-tile "
                    style={{
                      background:
                        location.pathname == item.path ? "#2b0366" : "",
                      height: item?.parent == "Library" ? "35px" : "",
                    }}
                    onClick={() => {
                      if (item.type == "link") externalLink()
                      else if (item.type == "dropdown") {
                      } else navigateToComponent(item)
                    }}
                  >
                    <span
                      style={{
                        marginLeft: item?.parent == "Library" ? "8px" : "",
                      }}
                    >
                      {" "}
                      {item.icon}
                    </span>

                    <div
                      style={{
                        color: "white",
                        textAlign: "center",
                        marginLeft: "14px",
                        fontSize: item?.parent == "Library" ? "12px" : "15px",
                      }}
                    >
                      {item.title}
                    </div>
                    {item.title == "Library" && (
                      <div
                        style={{ marginLeft: "auto" }}
                        onClick={() => setCollapsed(!collaped)}
                      >
                        {!collaped ? (
                          <AiOutlineCaretUp color="white" />
                        ) : (
                          <AiOutlineCaretDown color="white" />
                        )}
                      </div>
                    )}
                  </div>
                </>
              )
            })}
        </div>

        <div
          style={{
            width: "100%",
            background: "#2b0366",
            padding: "10px 10px",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        >
          {QuotaRender()}
        </div>

        {user?.customer_stripe_id ? (
          <div className="button-gradient" onClick={externalLink}>
            Manage billing
          </div>
        ) : (
          <>
            {user?.plan_type !== "Trial" ? (
              <div
                className="button-gradient"
                style={{ color: "white" }}
                onClick={() => navigateToComponent({ path: "topup" })}
              >
                Top up
              </div>
            ) : (
              <div
                className="button-gradient"
                style={{ color: "white" }}
                onClick={() => navigateToComponent({ path: "pricing" })}
              >
                Upgrade
              </div>
            )}
          </>
        )}

        <div>
          <Dropdown trigger={["click"]} overlay={rewardMenu} placement="top">
            <div
              className="ori-flex  ori-flex-ac sider-tile"
              style={
                {
                  // background: "#2b0366"
                }
              }
              // onClick={() => {
              //   if (item.type == 'link') externalLink()
              //   else if (item.type == 'dropdown') {
              //   } else navigateToComponent(item)
              // }}
            >
              <AiFillGift size={26} color={"white"} />

              <div
                style={{
                  marginLeft: "10px",
                  color: "white",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                Refer & Redeem
              </div>
            </div>
          </Dropdown>
        </div>
        <div style={{ borderTop: "0.5px solid white" }}></div>
        <div>
          <Dropdown trigger={["click"]} overlay={menu} placement="top">
            <div
              className="ori-flex  ori-flex-ac sider-tile"
              style={
                {
                  // background: "#2b0366"
                }
              }
              // onClick={() => {
              //   if (item.type == 'link') externalLink()
              //   else if (item.type == 'dropdown') {
              //   } else navigateToComponent(item)
              // }}
            >
              <Avatar src={user?.picture} />

              <div
                style={{
                  marginLeft: "10px",
                  color: "white",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                Profile
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default Sider