import { useAuth0 } from "@auth0/auth0-react"
import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import SpinnerLoader from "../SpinnerLoader"

function ProtectedRoute({ isAuthenticated, isLoading, user, logout }) {
  console.log(isAuthenticated, isLoading, "email verification")
  if (!isAuthenticated && !isLoading) return <Navigate to="/" />

  if (isLoading || !user) return <SpinnerLoader text="Loading Content . . ." />

  // if (!user?.email_verified) return <Navigate to="/verifyEmail" />;

  return <Outlet />
}

export default ProtectedRoute
