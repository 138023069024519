import { configureStore } from "@reduxjs/toolkit"
import logger from "redux-logger"
import auth from "./auth/reducer"
import page_details from "./page_details/reducer"
import routestate from "./routestate/reducer"

const store = configureStore({
  reducer: { auth, page_details, routestate },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "development")
      return getDefaultMiddleware().concat(logger)
    else return getDefaultMiddleware()
  },
})

export default store
