const states = {
  auth_details: {
    user: null,
    loggedIn: false,
    loading: false,
    error: null,
  },
}

export default states
