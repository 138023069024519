import { Checkbox, Radio, Switch } from "antd"
import React, { useEffect, useState } from "react"
import ShowMessage from "../../ShowMessage"

function Outline({
  outlineList,
  selectedOutline,
  setSelectedOutline,
  individual,
  setIndvidual,
}) {
  const [displayOutline, setDisplayOutline] = useState(outlineList)

  useEffect(() => {
    setSelectedOutline([])
    if (individual) {
      // let arr = [];
      // displayOutline?.map((item) => {
      //   item.split("\n")?.map((outline) => {
      //     if (outline) arr.push(outline);
      //   });
      // });
      // setDisplayOutline([...arr]);
    } else {
      setDisplayOutline(outlineList)
    }
  }, [individual, outlineList])

  const selectOutline = (item, selected) => {
    if (individual) {
      item.text = item?.text?.split(/^[- 0-9.]*/)[1]
      if (selected) {
        setSelectedOutline([
          ...selectedOutline?.filter((val) => item.key != val.key),
        ])
      } else setSelectedOutline([...selectedOutline, item])
    } else {
      if (selected) {
        setSelectedOutline([
          ...selectedOutline?.filter((val) => item.key != val.key),
        ])
      } else setSelectedOutline([item])
    }
  }

  if (!outlineList?.length)
    return <ShowMessage message={"No outlines generated"} />

  return (
    <div style={{ width: "100%" }}>
      <h1 className="ori-mrgn-tb-20">Select an outline </h1>
      <div className="ori-flex ori-flex-ctr ori-flex-ac">
        <h3 style={{ margin: 0 }}>Select individual outlines</h3>
        <Switch
          checked={individual}
          style={{ marginLeft: "20px" }}
          onChange={() => setIndvidual(!individual)}
        />
      </div>

      {displayOutline?.map((item, index) => {
        const selected = selectedOutline.find((item) => item.key == index)
          ? true
          : false
        return (
          <div
            style={{
              width: "100%",
              border: "1px solid rgba(109, 109, 109, 0.25)",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "14px",
              background: selected ? "#e0ccff" : "",
              padding: "10px 20px",
              flexDirection: individual ? "column" : "row",
            }}
            className={`ori-mrgn-tb-20 ori-flex ${
              individual ? "ori-flex-ctr" : "ori-flex-ac"
            } `}
            onClick={() => {
              if (!individual)
                selectOutline(
                  {
                    key: index,
                    text: item,
                  },
                  selected
                )

              // setSelectedOutline({
              //   key: index,
              //   text: item
              // });
            }}
          >
            {!individual ? <Radio checked={selected} /> : <></>}
            {!individual ? (
              <pre style={{ whiteSpace: "pre-wrap", marginLeft: "20px" }}>
                {item}
              </pre>
            ) : (
              item?.split("\n")?.map((val, ind) => {
                const selectedInd = selectedOutline.find(
                  (item) => item.key == ind + "" + index
                )
                  ? true
                  : false
                if (val)
                  return (
                    <div
                      onClick={() => {
                        selectOutline(
                          {
                            key: ind + "" + index,
                            text: val,
                          },
                          selectedInd
                        )
                      }}
                    >
                      <Checkbox checked={selectedInd} />
                      <span style={{ marginLeft: "10px" }}>{val}</span>
                    </div>
                  )
              })
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Outline
