import { Checkbox, Radio } from "antd"
import React, { useState, useEffect } from "react"
import ShowMessage from "../../ShowMessage"

const headlineList = [
  "hello danskldnsakl lknasdknlads",
  "hello danskldnsakl lknasdkasdadsnlads",
  "hello danskldnsakl lknasdknlads",
  "hello danskldnsakl lknasdknlads",
]

function Headline({ headlineList, selectedHeadline, setSelectedHeadline }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  if (!headlineList?.length)
    return (
      <ShowMessage
        message={"Please generate headlines by entering data in the form"}
      />
    )

  console.log(selectedHeadline)

  return (
    <div style={{ width: "100%" }}>
      <h1 className="ori-mrgn-tb-20">Select a headline </h1>
      {headlineList.map((item, index) => {
        if (!item) return
        return (
          <div
            style={{
              height: !isMobile ? "60px" : "",
              width: "100%",
              border: "1px solid rgba(109, 109, 109, 0.25)",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "15px",
              background: selectedHeadline.key == index ? "#e0ccff" : "",
              padding: "0px 20px",
            }}
            className="ori-mrgn-tb-20 ori-flex ori-flex-ac"
            onClick={() => {
              setSelectedHeadline({
                key: index,
                text: item,
              })
            }}
          >
            <Radio checked={selectedHeadline.key == index} />
            <span style={{ marginLeft: "20px" }}> {item}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Headline
