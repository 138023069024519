import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { getDataFromLocalStorage } from "../../data/utils/datautility"
import NavBar from "../NavBar"

function PricingPage({ user, isAuthenticated }) {
  // Paste the stripe-pricing-table snippet in your React component
  const [country, setCountry] = useState(getDataFromLocalStorage("country"))
  console.log(user, "useeee")
  if (user?.subscription_status && user?.plan_type !== "Trial")
    return <Navigate to="/tool" />

  if (user?.trialFinished) {
    return (
      <>
        {country == "IN" ? (
          <stripe-pricing-table
            pricing-table-id="prctbl_1Ly6eISI0uPxDyzfQgV2lEcH"
            publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
            client-reference-id={user?.userID}
            customer-email={user?.email}
          ></stripe-pricing-table>
        ) : (
          <stripe-pricing-table
            pricing-table-id="prctbl_1Ly6bWSI0uPxDyzfkgCFliRY"
            publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
            client-reference-id={user?.userID}
            customer-email={user?.email}
          ></stripe-pricing-table>
        )}
      </>
    )
  }

  return (
    <>
      {country == "IN" ? (
        <stripe-pricing-table
          pricing-table-id="prctbl_1LwVzMSI0uPxDyzfl8RlYRfi"
          publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
          client-reference-id={user?.userID}
          customer-email={user?.email}
        ></stripe-pricing-table>
      ) : (
        <stripe-pricing-table
          pricing-table-id="prctbl_1LwVwiSI0uPxDyzfsqnErqI2"
          publishable-key="pk_live_51LptSYSI0uPxDyzfcZ6sgBL1hDEW0NzPfzJDEovKDIJqpICa8uhRUyY2S42BZWW3eHw6WIRDG2yy5AqpWtfhYSdj00P0sdhzd9"
          client-reference-id={user?.userID}
          customer-email={user?.email}
        ></stripe-pricing-table>
      )}
    </>
  )
}

export default PricingPage
