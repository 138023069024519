import { CopyOutlined } from "@ant-design/icons"
import { Button, message } from "antd"
import React, { useState, useEffect } from "react"
import ShowMessage from "../../ShowMessage"

function Article({ article }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  if (!article) return <ShowMessage message={"No articles generated"} />

  return (
    <div
      className="ori-flex "
      style={{
        flexDirection: "column",
        height: "90%",
        marginBottom: isMobile ? "20px" : "0px",
      }}
    >
      <h1>Article</h1>

      <div
        className="ori-bg-white "
        style={{
          flexGrow: 1,
          width: "100%",
          position: "relative",
          border: "1px solid #d9d9d9",
          padding: "15px 10px 10px 10px",
          overflowY: "auto",
        }}
      >
        {" "}
        <Button
          style={{
            position: "absolute",
            right: 10,
            top: 5,
            height: 25,
            width: 25,
          }}
          onClick={() => {
            navigator.clipboard.writeText(article)
            message.info("Text Copied!")
          }}
          icon={<CopyOutlined />}
        />
        <div
          style={{ whiteSpace: "pre-wrap", padding: "10px" }}
          dangerouslySetInnerHTML={{ __html: article }}
        ></div>
      </div>
    </div>
  )
}

export default Article
