import React from "react"
import { connect } from "react-redux"
import { updateSelectedTiledata } from "../../data/redux/routestate/action"
import { Collapse } from "antd"
import { useNavigate } from "react-router-dom"
import { Mixpanel } from "../../data/MixPanel"
import { CleverTap } from "../../data/CleverTap"

const { Panel } = Collapse

function CardContainer({
  userId,
  item,
  background = "#ffffff",
  width = "100%",
  setSelectedMedia,
  height = "100px",
  marginLeft = "0px",
  dispatch,
}) {
  const navigate = useNavigate()
  return (
    <>
      {!(item.hasOwnProperty("childs") && Array.isArray(item.childs)) ? (
        <div
          className="ori-mrgn-t-10 ori-flex ori-flex-ac"
          style={{
            borderRadius: "5px",
            cursor: "pointer",
            width,
            height,
            minWidth: "300px",
            marginLeft,
            border: "1px solid rgba(109, 109, 109, 0.25)",
            background,
            padding: "26px 20px",
            overflow: "hidden",
          }}
          onClick={(e) => {
            const payload = { ...item }
            if (userId) {
              CleverTap.track("Library Visit Frontend", {
                ToolIdentifier: payload.title,
                Category: payload.type,
                Route: window.location.href,
                UserIdentity: userId,
              })
              Mixpanel.identify(userId)
              Mixpanel.track(payload?.title)
            }
            payload.icon = ""
            if (payload?.jobIdentifier === "longForm")
              navigate("/tool/" + payload?.path)
            else navigate("/tool/" + payload?.path + "/" + payload?.route)
            dispatch(updateSelectedTiledata({ selectedTile: payload }))
          }}
        >
          <div
            style={{
              color: "#7000f4",
              // border: "1px solid black"
            }}
          >
            {item.icon}
          </div>
          <div
            style={{
              marginLeft: "20px",
              // border: "1px solid black",
              width: "100%",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "80%",
              }}
              title={item.title}
            >
              {item.title}
            </div>
            <div
              style={{
                fontSize: "14px",
                width: "80%",
              }}
              title={item.description}
            >
              {item.description?.substring(0, 60)}
              {item?.description?.length > 60 ? "..." : ""}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Collapse style={{ backgroundColor: "white" }}>
            <Panel
              header={
                <h1 style={{ margin: 0, padding: 0 }}>
                  {" "}
                  {item.icon} {item.title}
                </h1>
              }
            >
              {item.childs.map((item1) => {
                return (
                  <div
                    className="ori-mrgn-t-20 ori-flex ori-flex-ac"
                    style={{
                      borderRadius: "5px",
                      cursor: "pointer",
                      width: "100%",
                      height,
                      minWidth: "300px",
                      border: "1px solid rgba(109, 109, 109, 0.25)",
                      background,
                      padding: "26px 36px",
                    }}
                    onClick={() => {
                      const payload = { ...item1 }
                      payload.icon = ""
                      if (payload?.jobIdentifier == "longForm")
                        navigate("/tool/" + payload?.path)
                      else
                        navigate(
                          "/tool/" + payload?.path + "/" + payload?.route
                        )
                      dispatch(
                        updateSelectedTiledata({ selectedTile: payload })
                      )
                    }}
                  >
                    <div style={{ color: "#7000f4" }}>{item1.icon}</div>
                    <div style={{ marginLeft: "20px" }}>
                      <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {item1.title}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {item1.description}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Panel>
          </Collapse>
        </>
      )}
    </>
  )
}

const mapstatetoprops = (state) => {
  return {
    selectedTile: state.routestate.selectedTile,
    userId: state.auth?.user?.userId,
  }
}

export default connect(mapstatetoprops)(CardContainer)
