import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Drawer } from "antd"
import { MenuUnfoldOutlined, CloseOutlined } from "@ant-design/icons"
import logoMobile from "../../data/assets/logoMobile.png"

import Sider from "../Sider"
import "./navbarMobile.css"

function NavBarMobile({ user, siderModule }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleDrawerToggle = () => {
    setMobileDrawerOpen((prevState) => !prevState)
  }

  return (
    <div className="navbar ori-flex ori-flex-ac">
      {isMobile && (
        <div onClick={handleDrawerToggle} style={{ marginLeft: "5%" }}>
          <MenuUnfoldOutlined style={{ fontSize: "25px", cursor: "pointer" }} />
        </div>
      )}
      <img
        src={logoMobile}
        alt="logo"
        style={{ width: "100px", marginLeft: "25%" }}
      />
      <Drawer
        placement="left"
        className="custom-drawer"
        bodyStyle={{ padding: 0 }}
        open={mobileDrawerOpen && isMobile}
        width="60vw"
        height="100vh"
        onClose={handleDrawerToggle}
        maskClosable={true}
      >
        <div className="drawer-content-wrapper">
          <CloseOutlined
            style={{
              color: "#fff",
              fontSize: "15px",
              position: "absolute",
              top: "16px",
              right: "16px",
              zIndex: 1
            }}
            onClick={handleDrawerToggle}
          />
          <Sider siderModule={siderModule} user={user} />
        </div>
      </Drawer>
    </div>
  );
}

function mapstateToProps(state) {
  return {
    user: state.auth.user,
  }
}

export default connect(mapstateToProps)(NavBarMobile)
