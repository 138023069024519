import { DeleteFilled, EditFilled } from "@ant-design/icons"
import { Button, message } from "antd"
import React, { useEffect, useState } from "react"
import { EditorContext } from "../../AppContainer"
import { fetchDataAndProceed } from "../../data/utils/datautility"
import { EditorComponent } from "../EditorComponent"
import Topbar from "../Topbar"

function Post() {
  const [postList, setPostList] = useState([])
  const [selectedPost, setSelectedPost] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)
  const [lg, setlg] = useState(window.innerWidth > 1200)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
      setlg(window.innerWidth >= 1200)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const onPostSelect = (post) => {
    setSelectedPost(post)
    var element = document.getElementById("scrollToPost")
    setTimeout(() => {
      !lg &&
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
    }, 100)
  }

  const deletePost = (post) => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/post/${post._id}`,
        method: "DELETE",
      },
      (err, res) => {
        if (!err && res) {
          message.success("Post successfully deleted")
          setPostList(postList.filter((item) => item._id != post._id))
        }
      }
    )
  }

  useEffect(() => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/post`,
        method: "GET",
      },
      (err, res) => {
        console.log(res)
        setPostList(res?.data?.data)
      }
    )
  }, [])

  return (
    <div className="ori-flex ori-flex-wrap">
      <div
        style={{
          width: lg ? "45%" : "100%",
        }}
      >
        <Topbar text="Post history" />

        <div className="ori-padding-20">
          <div
            style={{ filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.07))" }}
          >
            <div
              className="ori-flex ori-flex-jsb ori-flex-ac"
              style={{
                padding: "10px 0px",
                background: "#5816AE",
                height: "58px",
                color: "white",
              }}
            >
              <div style={{ marginLeft: "25px", fontSize: "14px" }}>
                Created At
              </div>
              <div
                style={{ width: "250px" }}
                className="ori-flex ori-flex-ac ori-flex-jsb"
              >
                <span style={{ fontSize: "14px" }}>Time</span>
                <span style={{ fontSize: "14px", marginRight: "20px" }}>
                  Action
                </span>
              </div>
            </div>
            <div
              style={{
                height: "600px",
                overflowY: "auto",
              }}
            >
              {postList &&
                postList.map((item, index) => {
                  const date = new Date(item.createdAt)

                  return (
                    <div
                      style={{
                        height: "70px",
                        background:
                          selectedPost._id == item._id
                            ? "rgb(224, 204, 255)"
                            : index % 2
                            ? "rgba(247, 247, 247, 0.5)"
                            : "",
                        cursor: "pointer",
                      }}
                      className="ori-flex ori-flex-jsb ori-flex-ac ori-bg-white"
                      onClick={() => onPostSelect(item)}
                    >
                      <div style={{ marginLeft: "25px", fontSize: "14px" }}>
                        {date.toDateString()}
                      </div>
                      <div
                        style={{ width: "250px" }}
                        className="ori-flex ori-flex-ac ori-flex-jsb"
                      >
                        <span style={{ fontSize: "14px" }}>
                          {date.toTimeString().substring(0, 9)}
                        </span>
                        <span style={{ fontSize: "14px", marginRight: "30px" }}>
                          <Button
                            icon={<DeleteFilled />}
                            onClick={() => deletePost(item)}
                          />
                        </span>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ width: lg ? "55%" : "100%", minWidth: lg ? "300px" : "0px" }}
        className="ori-bg-white ori-padding-20"
      >
        <div
          id="scrollToPost"
          dangerouslySetInnerHTML={{ __html: selectedPost?.post }}
          style={{
            background: "#f9f9f9",
            padding: "20px 20px",
          }}
        ></div>
      </div>
    </div>
  )
}

export default Post
