import React, { useEffect, useState } from "react"
import { AllTopics } from "../../data/config/constant"
import CardContainer from "../CardContainer"
import EditorComponent from "../EditorComponent"
import OutputGenerator from "../OutputGenerator"
import Topbar from "../Topbar"
import { updateSelectedTiledata } from "../../data/redux/routestate/action"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"

const socialMediaHandles = AllTopics.filter((item) => item.type == "email")

function EmailGenerator({ selectedTile, dispatch }) {
  const [selectedMedia, setSelectedMedia] = useState({})
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)
  const [lg, setlg] = useState(window.innerWidth > 1200)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
      setlg(window.innerWidth >= 1200)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const params = useParams()

  useEffect(() => {
    const selectedTile = socialMediaHandles.find(
      (item) => item.route == params.type
    )

    const payload = { ...selectedTile }
    payload.icon = ""

    dispatch(
      updateSelectedTiledata({
        selectedTile: payload,
      })
    )

    //  console.log(selectedTile, "hiii", params.type);
  }, [])

  return (
    <div className="ori-flex ori-flex-wrap">
      <div
        style={{
          width: selectedTile.title && lg ? "45%" : "100%",
        }}
      >
        <Topbar
          text={selectedTile.title ? selectedTile.title : "Email Generator"}
        />
        {selectedTile.title ? (
          <OutputGenerator {...selectedTile} />
        ) : (
          <div
            style={{
              padding: !isMobile ? "0px 10px" : "0px 0px",
              height: "calc(100vh - 100px)",
              overflowY: "auto",
              marginTop: "10px",
            }}
          >
            <div
              className="ori-flex"
              style={{
                justifyContent: isMobile ? "center" : "",
                marginBottom: isMobile ? "20px" : "0px",
                overflowY: "auto",
                marginTop: "10px",
                flexWrap: "wrap",
              }}
            >
              {socialMediaHandles.map((item) => {
                return (
                  <CardContainer
                    item={item}
                    width="31%"
                    marginLeft={!isMobile ? "20px" : "0px"}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
      {selectedTile.title && (
        <div
          style={{ width: lg ? "55%" : "100%", height: "100vh" }}
          className={`ori-bg-white ${
            !isMobile ? "ori-padding-20" : "ori-padding-10"
          }`}
        >
          <EditorComponent />
        </div>
      )}
    </div>
  )
}
const mapstatetoprops = (state) => {
  return {
    selectedTile: state.routestate.selectedTile,
  }
}

export default connect(mapstatetoprops)(EmailGenerator)
