import React, { useEffect } from "react"
import { useSelector } from "react-redux"

const PaymentSuccess = (user) => {
  const stripeCustomerId = useSelector((state) => {
    return state?.auth?.user?.stripeCustomerId
  })

  useEffect(() => {
    console.log("user details from payment success page ", user)

    if (window.tap && stripeCustomerId) {
      console.log("trial", stripeCustomerId)
      window.tap("trial", stripeCustomerId)
    }
  }, [stripeCustomerId])

  return (
    <>
      <br />
      <br />
      <h1 style={{ fontSize: "3rem", textAlign: "center" }}>
        Thankyou for purchasing the plan!
      </h1>
    </>
  )
}

export default PaymentSuccess
