import React, { useState, useEffect } from "react"
import Appsumo from "../../data/assets/Appsumo.png"
import G2 from "../../data/assets/G2.png"
import Capterra from "../../data/assets/Capterra.png"
import Banner from "../../data/assets/Appreview.png"
import FB from "../../data/assets/FB.png"
import Linkedin from "../../data/assets/Linkedin.png"
import Twitter from "../../data/assets/twitter.png"

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"

const socialWebsites = [
  {
    words: 2000,
    title: "Review on G2",
    icon: G2,
    share: "https://www.g2.com/products/autorytr/take_survey",
  },

  {
    words: 2000,
    title: "Review on Capterra",
    icon: Capterra,
    share: "https://reviews.capterra.com/new/278042",
  },

  {
    words: 2000,
    title: "Review on Appsumo",
    icon: Appsumo,
    share: "https://appsumo.com/products/autorytr/#reviews",
  },
]

const shoutLoud = [
  {
    words: 1000,
    title: "Review on facebook",
    icon: FB,
  },
  {
    words: 1000,
    title: "Review on linkedin",
    icon: Linkedin,
  },
  {
    words: 1000,
    title: "Review on twitter",
    icon: Twitter,
  },
]
function Appreview() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480)
  const [lg, setlg] = useState(window.innerWidth > 1200)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480)
      setlg(window.innerWidth >= 1200)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <div>
        <img src={Banner} style={{ width: "100%" }}></img>
      </div>
      <div style={{ padding: isMobile ? "0px 30px" : "0px 80px" }}>
        <div className="ori-flex ori-flex-ac">
          <div
            style={{ fontSize: "80px", color: "lightgray", fontWeight: "800" }}
          >
            1.
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h2 className="ori-font-bolder"> Increase your quota</h2>
            Loved using Autortyr? Here's how you can increase your quota up to
            <b> 9000 words</b>. 🤩
          </div>
        </div>

        <div
          className="ori-flex ori-flex-jsb"
          style={{ flexDirection: lg ? "row" : "column" }}
        >
          <div style={{ width: !isMobile ? "40%" : "" }}>
            <h1 style={{ marginBottom: "10px" }}>
              Spread{" "}
              <span style={{ color: "#3F08AA" }} className="ori-font-bold">
                the word!
              </span>
            </h1>
            <span>
              Publish a detailed review of Autorytr on these platforms &{" "}
              <b>earn 2000 free words</b> right away for each successful review
              submission.
            </span>

            <div
              className="ori-flex "
              style={{
                flexWrap: "wrap",
                marginBottom: isMobile ? "10px" : "0px",
                marginTop: isMobile ? "10px" : "0px",
              }}
            >
              {socialWebsites?.map((item) => {
                return (
                  <div
                    className="ori-flex ori-flex-ac ori-flex-jsb"
                    style={{
                      marginTop: "10px",
                      borderRadius: "5px",
                      marginRight: isMobile ? "0px" : "20px",
                      width: isMobile ? "100%" : "",
                      height: "120px",
                      minWidth: lg ? "330px" : "",
                      border: "1px solid rgba(109, 109, 109, 0.25)",
                      background: "#ffffff",
                      padding: "26px 20px",
                    }}
                  >
                    <div>
                      <img src={item.icon} style={{ width: "64px" }}></img>
                    </div>
                    <div
                      className="ori-flex ori-flex-ctr ori-flex-ac"
                      style={{
                        flexDirection: "column",
                        padding: isMobile ? "5px" : "0px",
                      }}
                    >
                      <div style={{ fontWeight: "700" }}>
                        + {item.words} Words
                      </div>
                      <div
                        className="button-gradient"
                        style={{
                          width: "200px",
                          height: "55px",
                          color: "white",
                          borderRadius: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (item.share) window.open(item.share, "_blank")
                        }}
                      >
                        {item.title}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div style={{ width: !isMobile ? "40%" : "" }}>
            <h1 style={{ marginBottom: "10px" }}>
              Shoutout{" "}
              <span style={{ color: "#3F08AA" }} className="ori-font-bold">
                loud!
              </span>
            </h1>
            <span>
              Give Autorytr a shoutout on social media &{" "}
              <b>earn 1000 free words </b>
              for each shoutout! 📢
            </span>

            <div
              className="ori-flex "
              style={{
                flexWrap: "wrap",
                marginTop: isMobile ? "10px" : "0px",
              }}
            >
              {shoutLoud?.map((item, index) => {
                let Wrapper

                if (index == 0) Wrapper = FacebookShareButton
                if (index == 1) Wrapper = LinkedinShareButton
                if (index == 2) Wrapper = TwitterShareButton

                return (
                  <Wrapper
                    url={"https://autorytr.com/"}
                    style={{
                      width: isMobile ? "100%" : "",
                      minWidth: lg ? "330px" : "",
                    }}
                  >
                    {" "}
                    <div
                      className="ori-flex ori-flex-ac ori-flex-jsb"
                      style={{
                        marginTop: "10px",
                        borderRadius: "5px",
                        height: "120px",
                        border: "1px solid rgba(109, 109, 109, 0.25)",
                        background: "#ffffff",
                        padding: "26px 20px",
                      }}
                    >
                      <div>
                        <img src={item.icon} style={{ width: "64px" }}></img>
                      </div>
                      <div
                        className="ori-flex ori-flex-ctr ori-flex-ac"
                        style={{
                          flexDirection: "column",
                          padding: isMobile ? "5px" : "0px",
                        }}
                      >
                        <div style={{ fontWeight: "700" }}>
                          + {item.words} Words
                        </div>
                        <div
                          className="button-gradient"
                          style={{
                            width: "200px",
                            height: "55px",
                            color: "white",
                            borderRadius: "30px",
                            cursor: "pointer",
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                )
              })}
            </div>
          </div>
        </div>

        <div
          style={{ borderTop: "0.5px solid gray" }}
          className="ori-mrgn-tb-20"
        ></div>
        <div className="ori-flex ori-flex-ac">
          <div
            style={{ fontSize: "80px", color: "lightgray", fontWeight: "800" }}
          >
            2.
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h2> What Next?</h2>
            It's time to claim your rewards! 🎁 But first, we need to check
            whether your reviews have been submitted & approved.
          </div>
        </div>
        <ul>
          <li className="ori-mrgn-tb-20">
            Email us a screenshot of your review at
            <a href="mailto: contact@autorytr.com"> contact@autorytr.com</a>
          </li>
          <li className="ori-mrgn-tb-20">
            Our team will verify your review within 2 business days.
          </li>
          <li className="ori-mrgn-tb-20">
            Your account will be credited with the reward points post successful
            verification.
          </li>
        </ul>
      </div>
    </>
  )
}

export default Appreview
