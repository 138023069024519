import { message } from "antd"
import { fetchDataAndProceed } from "../../utils/datautility"

export function postTweet(data) {
  return (dispatch) => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/social/tweet`,
        method: "POST",
        data,
      },
      (err, res) => {
        if (!err && res) {
          console.log(res)
          message.success("Tweet Posted")
        }
      }
    )
  }
}
