import React, { useEffect, useState } from "react"
import "./referrals.css"
import { Input, Button, Pagination, message } from "antd"
import {
  CopyOutlined,
  SendOutlined,
} from "@ant-design/icons"

import { fetchDataAndProceed } from "../../data/utils/datautility"

// import { VIRAL_LOOP_DATA } from "../../data/config/constant"

import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share"

const Referral = ({ user }) => {
  const [referrals, setReferrals] = useState([])
  const [totalReferrals, setTotoalReferrals] = useState(0)
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)

  const [email, setEmail] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
  const [lg, setlg] = useState(window.innerWidth > 1200);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
      setlg(window.innerWidth >= 1200);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let react_app_url = `https://app.autorytr.com/?referralCode=${user._id}`

  if (process.env.NODE_ENV === "development") {
    react_app_url = `https://localhost:3000/?referralCode=${user._id}`
  }

  // const [totalPages, setTotoalPages] = useState(1)

  // useEffect(() => {
  //     var addScript = document.createElement('script')
  //     addScript.appendChild(document.createTextNode(VIRAL_LOOP_DATA[process.env.NODE_ENV]))
  //     document.body.appendChild(addScript)

  //     if (window.campaign && window.campaign.addHook && window.campaign.identify) {
  //         window.campaign.addHook("boot", () => {
  //             if (window.campaign.user && window.campaign.user.referralCode) {
  //                 setTimeout(() => document.getElementById("widget").style.display = "block", 1000)
  //             } else {
  //                 const vl_user = {
  //                     firstname: user.given_name ?? "",
  //                     lastname: user.family_name ?? "",
  //                     email: user.email
  //                 }

  //                 window.campaign.identify(vl_user, () => {
  //                     setTimeout(() => document.getElementById("widget").style.display = "block", 1000)
  //                 })
  //             }
  //         })
  //     }

  // }, [])

  const loadMoreReferrals = (e) => {
    setSkip((e - 1) * limit)
  }

  const inviteViaEmail = () => {
    if (!email) {
      message.error("Please enter email address, whom you want to refer...")
      return
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      message.error("Please enter valid email address...")
      return
    }

    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/user/refer_via_email`,
        method: "POST",
        data: {
          to: email,
          referral_url: react_app_url,
          referrer: user,
        },
      },
      (err, res) => {
        if (err) console.log("Error = ", err)
        else {
          message.info(`Invitation Successfully send to ${email}`)
          setEmail("")
        }
      }
    )
  }

  useEffect(() => {
    fetchDataAndProceed(
      {
        url: `${process.env.REACT_APP_BASE_URL}/user/referral_history/${user._id}?limit=${limit}&skip=${skip}`,
      },
      (err, res) => {
        if (err) console.log(err)
        else {
          setReferrals(res?.data?.data?.referrals ?? [])
          setTotoalReferrals(res?.data?.data?.totoalDocs ?? 0)
        }
      }
    )
  }, [skip])

  return (
    <>
      <div
        style={{
          zIndex: 999,
          padding: "0.7% 2%",
          borderBottom: "0.01rem solid lightgrey",
          position: "sticky",
          top: 0,
          background: "rgba(255, 255, 255, 0.8",
          backdropFilter: "blur(10px)",
        }}
      >
        <h2>Referrals</h2>
      </div>

      {/* Sample URL to share https://dev-app.autorytr.com?referralCode=yg1dffp&refSource=whatsapp */}

      {/* Header Area */}
      <div className="referral-container">
        {/* Email and social media share */}
        <div style={{ display: "flex" , flexDirection: !isMobile ? "row" : "column"}}>
          <div>
            <h2 style={{ marginBottom: "0.4rem" }}>Get upto 3000 words 🤩</h2>
            <p>
              Referrer will get 3000 words as reward points, and referree will
              get 2000 additional points when they signup on autorytr via
              referred link.
            </p>

            <br />

            <h3 style={{ marginBottom: "0.4rem" }}>Copy Link</h3>

            <Input.Group compact style={{ display: "flex" }}>
              <Input
                style={{ flex: 1, width: "calc(100% - 200px)" }}
                value={react_app_url}
              />
              <Button
                type="primary"
                onClick={() => {
                  navigator.clipboard.writeText(react_app_url)
                  message.info("Link Copied!")
                }}
              >
                <CopyOutlined /> Copy{" "}
              </Button>
            </Input.Group>

            <br />

            <h3 style={{ marginBottom: "0.4rem" }}>Email Your Invitation</h3>
            <Input.Group compact style={{ display: "flex" }}>
              <Input
                type="email"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                placeholder="email@examle.com"
                style={{ flex: 1, width: "calc(100% - 200px)" }}
              />
              <Button
                type="primary"
                onClick={inviteViaEmail}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "5.4rem",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ transform: "rotate(-45deg)" }}>
                  {" "}
                  <SendOutlined />{" "}
                </span>
                <span> Send </span>
              </Button>
            </Input.Group>
          </div>

          <div style={{marginTop: isMobile ?"20px" : "0px"}}>
            <h2 style={{ marginBottom: "0.4rem" }}>Share with Social Media</h2>
            <br />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <FacebookShareButton className="icon-link" url={react_app_url}>
                <FacebookIcon size={60} round={true} />
              </FacebookShareButton>

              <FacebookMessengerShareButton
                className="icon-link"
                url={react_app_url}
              >
                <FacebookMessengerIcon size={60} round={true} />
              </FacebookMessengerShareButton>

              <TwitterShareButton className="icon-link" url={react_app_url}>
                <TwitterIcon size={60} round={true} />
              </TwitterShareButton>

              <TelegramShareButton className="icon-link" url={react_app_url}>
                <TelegramIcon size={60} round={true} />
              </TelegramShareButton>

              <LinkedinShareButton className="icon-link" url={react_app_url}>
                <LinkedinIcon size={60} round={true} />
              </LinkedinShareButton>

              <EmailShareButton className="icon-link" url={react_app_url}>
                <EmailIcon size={60} round={true} />
              </EmailShareButton>

              <WhatsappShareButton className="icon-link" url={react_app_url}>
                <WhatsappIcon size={60} round={true} />
              </WhatsappShareButton>

              {/* <a className="icon-link" href=""> <FacebookOutlined style={{ display: "grid", placeItems: "center", fontSize: "1.3rem", border: "0.01rem solid grey", borderRadius: "0.3rem", color: "black", width: "5rem", height: "3rem", backgroundColor: "lightyellow" }} /> </a>
                            <a className="icon-link" href=""> <InstagramOutlined style={{ display: "grid", placeItems: "center", fontSize: "1.3rem", border: "0.01rem solid grey", borderRadius: "0.3rem", color: "black", width: "5rem", height: "3rem", backgroundColor: "lightyellow" }} /> </a>
                            <a className="icon-link" href=""> <TwitterOutlined style={{ display: "grid", placeItems: "center", fontSize: "1.3rem", border: "0.01rem solid grey", borderRadius: "0.3rem", color: "black", width: "5rem", height: "3rem", backgroundColor: "lightyellow" }} /> </a>
                            <a className="icon-link" href=""> <LinkedinOutlined style={{ display: "grid", placeItems: "center", fontSize: "1.3rem", border: "0.01rem solid grey", borderRadius: "0.3rem", color: "black", width: "5rem", height: "3rem", backgroundColor: "lightyellow" }} /> </a>
                            <a className="icon-link" href=""> <YoutubeOutlined style={{ display: "grid", placeItems: "center", fontSize: "1.3rem", border: "0.01rem solid grey", borderRadius: "0.3rem", color: "black", width: "5rem", height: "3rem", backgroundColor: "lightyellow" }} /> </a>
                            <a className="icon-link" href=""> <MailOutlined style={{ display: "grid", placeItems: "center", fontSize: "1.3rem", border: "0.01rem solid grey", borderRadius: "0.3rem", color: "black", width: "5rem", height: "3rem", backgroundColor: "lightyellow" }} /></a>
                            <a className="icon-link" href=""> <WhatsAppOutlined style={{ display: "grid", placeItems: "center", fontSize: "1.3rem", border: "0.01rem solid grey", borderRadius: "0.3rem", color: "black", width: "5rem", height: "3rem", backgroundColor: "lightyellow" }} /></a> */}
            </div>
          </div>
        </div>

        {/* Referral history */}
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <h2 style={{ color: "blue" }}>Referral History</h2>
            <p style={{ color: "green", fontWeight: "bold" }}>
              {user?.totalEarnedPoint ?? 0} Reward Words Earned Till Now...
            </p>
          </div>

          <div
            style={{
              zIndex: 999,
              position: "sticky",
              top: 0,
              display: "flex",
              backgroundColor: "white",
              marginBottom: "0.5rem",
              padding: "0.1rem",
              width: "100%",
              justifyContent: "space-between",
              background: "rgba(255, 255, 255, 0.8",
              backdropFilter: "blur(10px)",
              alignItems: "center",
            }}
          >
            <h3 style={{ padding: "0.25rem", width: "5%" }}>Sr</h3>
            <h3 style={{ padding: "0.25rem", width: "15%" }}>Referrer Name</h3>
            <h3 style={{ padding: "0.5rem", width: "25%" }}>Referrer Email</h3>
            <h3 style={{ padding: "0.5rem", width: "15%" }}>Reward point</h3>
            <h3 style={{ padding: "0.5rem", width: "15%" }}>Referree Name</h3>
            <h3 style={{ padding: "1.5rem", width: "25%" }}>Referree Email</h3>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            {referrals?.length > 0 ? (
              <>
                {referrals?.map((referral, index) => {
                  return (
                    <div
                      style={{
                        marginBottom: "0.5rem",
                        display: "flex",
                        backgroundColor: "white",
                        padding: "0.1rem",
                        width: "100%",
                        justifyContent: "space-between",
                        border: "0.01rem solid grey",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ padding: "0.5rem", width: "5%" }}>
                        {index + 1}
                      </span>
                      <span style={{ padding: "0.5rem", width: "15%" }}>
                        {referral.referrer_details.name}
                      </span>
                      <span style={{ padding: "0.5rem", width: "25%" }}>
                        {referral.referrer_details.email}
                      </span>
                      <span style={{ padding: "0.5rem", width: "15%" }}>
                        {referral?.referrer_details?.reward_words ?? 0}
                      </span>
                      <span style={{ padding: "0.5rem", width: "15%" }}>
                        {referral.referree_details.name}
                      </span>
                      <span style={{ padding: "0.5rem", width: "25%" }}>
                        {referral.referree_details.email}
                      </span>
                    </div>
                  )
                })}
                {/* pagination */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Pagination
                    pageSize={limit}
                    total={totalReferrals}
                    onChange={loadMoreReferrals}
                  />
                </div>
              </>
            ) : (
              <>
                <br />
                <center>
                  <h1>No Referrals Found.</h1>
                </center>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Referral